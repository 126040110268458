import { TOOLTIP } from 'common/consts/tooltip.js';
import { CARD_EFFECT_TYPES } from 'common/consts/types/card-effect.js';

import { BaseCardEffect } from '../base.js';

export class FortificateStructuresCardEffect extends BaseCardEffect {
  constructor(params) {
    super(params);

    this.type = CARD_EFFECT_TYPES.FORTIFICATE_STRUCTURES;
  }

  getTooltipTypes() {
    return [TOOLTIP.TYPES.FORTIFICATIONS];
  }

  execute({ kingdom, card }) {
    const { fortifications } = card.getStats();

    const fields = this.game.mapController
      .getAllFields()
      .filter((field) => field.ownedBy(kingdom) && field.structure && field.structure.isBuilding);

    for (let i = 0; i < fields.length; i++) {
      fields[i].changeFortifications(fortifications);
    }
  }
}
