import { ENGLISH_NAME } from 'common/languages/english.js';
import { POLISH_NAME } from 'common/languages/polish.js';

const LANGUAGE = '-settings-language';
const SOUND_VOLUME_KEY = '-settings-sound-volume';

export class SettingsController {
  constructor(game) {
    this.game = game;

    this.loadFromLocalStorage();
  }

  loadFromLocalStorage() {
    this.soundVolume = localStorage.getItem(SOUND_VOLUME_KEY) || 0.65;
    this.language = localStorage.getItem(LANGUAGE) || ENGLISH_NAME;
  }

  setSoundVolume(value) {
    this.soundVolume = value;

    localStorage.setItem(SOUND_VOLUME_KEY, value);
  }

  getSoundVolume() {
    return this.soundVolume;
  }

  setLanguage(language) {
    this.language = language;

    localStorage.setItem(LANGUAGE, language);

    this.game.eventsController.runEvent('regenerateTexts');
  }

  getLanguage() {
    return this.language;
  }

  getLanguageOptions() {
    return [ENGLISH_NAME, POLISH_NAME];
  }
}
