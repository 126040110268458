import { TOOLTIP } from 'common/consts/tooltip.js';
import { CARD_EFFECT_TYPES } from 'common/consts/types/card-effect.js';
import { STRUCTURES } from 'common/consts/types/structures.js';

import { randomize } from 'common/helpers/array.js';

import { BaseCardEffect } from '../base.js';

export class PlaceRandomCannonsRandomlyCardEffect extends BaseCardEffect {
  constructor(params) {
    super(params);

    this.type = CARD_EFFECT_TYPES.PLACE_RANDOM_CANNONS_RANDOMLY;
  }

  getTooltipTypes() {
    return [TOOLTIP.TYPES.RANDOM_CANNON];
  }

  execute({ kingdom, card }) {
    const { numberOfCannons } = card.getStats();

    const ownEmptyFields = this.game.mapController.getAllFields().filter((field) => field.ownedBy(kingdom) && field.empty());

    const ownRandomFields = randomize(ownEmptyFields);

    const max = Math.min(numberOfCannons, ownRandomFields.length);
    for (let i = 0; i < max; i++) {
      const field = ownRandomFields[i];
      this.game.structuresCreator.createStructure(field, STRUCTURES.RANDOM_CANNON);
    }
  }

  canBePlayed({ kingdom }) {
    return this.checkForOwnEmptyFields(kingdom) || this.getCan();
  }
}
