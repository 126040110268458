import { TOOLTIP } from 'common/consts/tooltip.js';
import { CARD_EFFECT_TYPES } from 'common/consts/types/card-effect.js';
import { STRUCTURES } from 'common/consts/types/structures.js';

import { randomize } from 'common/helpers/array.js';

import { BaseCardEffect } from '../base.js';

export class PlaceMinesRandomlyCardEffect extends BaseCardEffect {
  constructor(params) {
    super(params);

    this.type = CARD_EFFECT_TYPES.PLACE_MINES_RANDOMLY;
  }

  getTooltipTypes() {
    return [TOOLTIP.TYPES.MINE];
  }

  execute({ kingdom, card }) {
    const { numberOfMines } = card.getStats();

    const allOwnFields = this.game.mapController.getAllFields().filter((field) => field.ownedBy(kingdom) && field.empty());

    const ownRandomFields = randomize(allOwnFields);

    const max = Math.min(ownRandomFields.length, numberOfMines);

    for (let i = 0; i < max; i++) {
      const field = ownRandomFields[i];
      this.game.structuresCreator.createStructure(field, STRUCTURES.MINE);
    }
  }
}
