import * as PIXI from 'pixi.js';

import { EFFECTS_ICON_SIZE } from 'client/consts/layout.js';
import { Z_INDEX } from 'client/consts/z-index.js';

import { TOOLTIP } from 'common/consts/tooltip.js';

import { EntityBase } from '../entity-base.js';

export class EffectBase extends EntityBase {
  constructor(params) {
    super({ ...params, priority: 1 });

    this.kingdom = params.kingdom;
    this.parent = params.parent;

    this.visible = params.visible;

    this.sprites = {};

    this.tooltipTypes = [];
    this.tooltipCategory = TOOLTIP.CATEGORIES.EFFECT;
  }

  getKingdom() {
    return this.kingdom;
  }

  setPosition(position) {
    if (!this.hasSprite()) return false;

    this.position = position;

    this.sprites.container.x = this.position.x;
    this.sprites.container.y = this.position.y;
  }

  createSprites() {
    this.createSpritesContainer();
    this.createIconSprites();
    this.updateTexts();
    this.handleMouseEvents();
  }

  createSpritesContainer() {
    this.sprites.container = new PIXI.Container();
    this.sprites.container.zIndex = Z_INDEX.EFFECT;
    this.sprites.container.eventMode = 'static';

    this.registerSprite(this.sprites.container);
  }

  createIconSprites() {
    this.sprites.text = this.game.texturesManager.createOutlinedText();

    this.sprites.text.x = EFFECTS_ICON_SIZE / 2 - 8;
    this.sprites.text.y = EFFECTS_ICON_SIZE / 2;
    this.sprites.text.anchor.set(0.5);

    this.sprites.icon = this.game.texturesManager.createStandardSprite(this.getIconName());
    this.sprites.icon.scale.set(0.5);

    this.sprites.container.addChild(this.sprites.icon);
    this.sprites.container.addChild(this.sprites.text);
  }

  updateTexts() {}

  handleMouseEvents() {
    this.sprites.container.on('pointerover', this.onMouseEnter);
    this.sprites.container.on('pointerout', this.onMouseLeave);
  }

  onMouseEnter = () => {
    this.game.eventsController.runEvent('tooltipableMouseEnter', { source: this });
  };

  onMouseLeave = () => {
    this.game.eventsController.runEvent('tooltipableMouseLeave', { source: this });
  };

  getTooltipText() {
    return '';
  }

  getIconName() {}

  hasSprite() {
    return !!this.sprites.container;
  }

  onCycleEnd() {}

  update() {}

  onKingdomRedraw() {}

  toSocketData() {
    return {
      id: this.id,
    };
  }

  destroy() {
    super.destroy();
    if (this.hasSprite()) this.removeSprite(this.sprites.container);
  }

  getPosition() {
    return this.position.clone();
  }

  getTooltipTypes() {
    return this.tooltipTypes;
  }

  getTooltipCategory() {
    return this.tooltipCategory;
  }

  getBounds() {
    return this.sprites.container.getBounds();
  }
}
