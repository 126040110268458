import { TOOLTIP } from 'common/consts/tooltip.js';
import { CARD_EFFECT_TYPES } from 'common/consts/types/card-effect.js';

import { BaseCardEffect } from '../base.js';

export class LightweightCardEffect extends BaseCardEffect {
  constructor(params) {
    super(params);

    this.type = CARD_EFFECT_TYPES.LIGHTWEIGHT;
  }

  getTooltipTypes() {
    return [TOOLTIP.TYPES.LIGHTWEIGHT];
  }
}
