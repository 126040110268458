import { ENTITIES } from 'common/consts/types/entities.js';

import { Point } from 'common/data-types/point.js';
import { Bullet } from 'common/entities/bullet.js';

export class EntitiesController {
  constructor(game) {
    this.entitiesMap = {
      [ENTITIES.BULLET]: Bullet,
    };

    this.entities = [];

    this.game = game;
    this.serverPart = game.serverPart;

    this.registerEventListeners();
  }

  registerEventListeners = () => {
    this.game.eventsController.addListener('regenerateTexts', this);
  };

  registerEntity(entity) {
    this.entities.push(entity);
    this.entities = this.entities.sort((a, b) => a.priority - b.priority);
  }

  removeEntity(entity) {
    if (this.game.shouldSendEvent()) this.game.connectionController.onDestroyEntity(entity);

    for (let i = this.entities.length - 1; i >= 0; i--) {
      if (entity.id === this.entities[i].id) {
        this.entities.splice(i, 1);
        return true;
      }
    }
  }

  destroyFromSD(entitiesData) {
    entitiesData.map(this.destroySingleFromSocketData);
  }

  destroySingleFromSocketData = (entityData) => {
    const { id } = entityData;

    const entity = this.findById(id);
    if (!entity) return;

    entity.destroy();
  };

  create(name, params) {
    const klass = this.entitiesMap[name];
    const finalParams = {
      ...params,
      game: this.game,
    };

    const entity = new klass(finalParams);

    if (this.game.shouldSendEvent()) this.game.connectionController.onCreateEntity(entity);

    return entity;
  }

  toSocketData() {
    const socketableEntities = this.entities.filter((entity) => entity.socketable);
    return socketableEntities.map((entity) => entity.toSocketData());
  }

  resetToSocketData(data, delta) {
    this.destroySocketable();
    this.createFromSD(data, delta);
  }

  createFromSD(data, delta) {
    data.forEach((entityData) => this.createSingleFromSocketData(entityData, delta));
  }

  createSingleFromSocketData = (data, delta = 0) => {
    const { type, id, speed, position, power, lifeTime, hostId } = data;

    const klass = this.entitiesMap[type];

    const params = {
      id,
      position: new Point(position.x, position.y),
      speed: new Point(speed.x, speed.y),
      power,
      lifeTime,
      game: this.game,
      owner: this.game.kingdomsController.getKingdomById(hostId),
    };

    const entity = new klass(params);

    if (delta > 0) entity.activate(delta);

    return entity;
  };

  findById(id) {
    return this.entities.find((entity) => entity.id === id);
  }

  activate(delta, paused) {
    this.eachEntity((entity) => {
      if (paused && !entity.unpauseable) return;
      entity.activate(delta);
    });
  }

  onCycleEnd(delta) {
    this.eachEntity((entity) => entity.onCycleEnd(delta));
  }

  eachEntity(callback) {
    const entitiesCopy = [...this.entities];
    entitiesCopy.forEach((entity) => !entity.destroyed && callback(entity));
  }

  afterResize() {
    this.eachEntity((entity) => entity.afterResize());
  }

  onRegenerateTexts = () => {
    this.eachEntity((entity) => entity.regenerateTexts());
  };

  onZoom(zoomValue) {
    this.eachEntity((entity) => entity.onZoom(zoomValue));
  }

  numberOfBullets() {
    let count = 0;

    this.eachEntity((entity) => {
      if (entity.type === ENTITIES.BULLET) count++;
    });

    return count;
  }

  destroyAllEntities() {
    this.eachEntity((entity) => entity.destroy());
  }

  destroySocketable() {
    this.eachEntity((entity) => entity.socketable && entity.destroy());
  }

  destroy() {
    this.destroyAllEntities();
  }
}
