import { TOOLTIP } from 'common/consts/tooltip.js';
import { CARD_EFFECT_TYPES } from 'common/consts/types/card-effect.js';

import { BaseCardEffect } from '../base.js';

export class RefillCardEffect extends BaseCardEffect {
  constructor(params) {
    super(params);

    this.type = CARD_EFFECT_TYPES.REFILL;
  }

  getTooltipTypes() {
    return [TOOLTIP.TYPES.REFILL];
  }

  afterExecute({ kingdom }) {
    kingdom.orderDrawingCards(1);
  }
}
