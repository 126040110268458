import * as PIXI from 'pixi.js';

import { CANCEL_BUTTON_HEIGHT, CANCEL_BUTTON_TOP } from 'client/consts/layout.js';
import { Z_INDEX } from 'client/consts/z-index.js';
import { withLeftClick } from 'client/utils/with-left-click.js';

import { IMAGES } from 'common/consts/types/images.js';

import { InterfaceBase } from '../interface-base.js';

export class CancelButton extends InterfaceBase {
  constructor(params) {
    super(params);

    this.parent = params.parent;

    this.visible = false;

    this.size = CANCEL_BUTTON_HEIGHT;

    this.createSprites();
    this.updatePosition();
  }

  createSprites() {
    this.createSpritesContainer();
    this.createButtonSprites();
  }

  createSpritesContainer() {
    this.spritesContainer = new PIXI.Container();
    this.spritesContainer.zIndex = Z_INDEX.CANCEL_BUTTON;
    this.spritesContainer.visible = false;

    this.spritesContainer.eventMode = 'static';
    this.spritesContainer.cursor = 'pointer';

    this.spritesContainer.on('pointerdown', withLeftClick(this.onClick));
    this.spritesContainer.on('pointerover', this.onMouseEnter);
    this.spritesContainer.on('pointerout', this.onMouseLeave);

    this.registerSprite(this.spritesContainer);
  }

  createButtonSprites() {
    this.buttonSprite = this.game.texturesManager.createStandardSprite(IMAGES.INTERFACE.BUTTONS.SMALL_CANCEL);
    this.buttonSprite.anchor.set(0.5, 0);

    this.buttonSpriteHovered = this.game.texturesManager.createStandardSprite(IMAGES.INTERFACE.BUTTONS.SMALL_CANCEL_HOVERED);
    this.buttonSpriteHovered.anchor.set(0.5, 0);
    this.buttonSpriteHovered.visible = false;

    this.spritesContainer.addChild(this.buttonSprite);
    this.spritesContainer.addChild(this.buttonSpriteHovered);
  }

  show() {
    this.setVisible(true);
  }

  hide() {
    this.setVisible(false);
  }

  setVisible(value) {
    if (value === this.visible) return false;

    this.visible = value;
    this.spritesContainer.visible = value;
  }

  updatePosition() {
    this.spritesContainer.x = window.innerWidth / 2;
    this.spritesContainer.y = CANCEL_BUTTON_TOP;
  }

  onFocusedOnBoardChange = () => {
    this.updatePosition();
  };

  afterResize() {
    this.updatePosition();
  }

  onClick = () => {
    this.parent.onCancelButtonClick();
  };

  onMouseEnter = () => {
    this.buttonSprite.visible = false;
    this.buttonSpriteHovered.visible = true;
  };

  onMouseLeave = () => {
    this.buttonSprite.visible = true;
    this.buttonSpriteHovered.visible = false;
  };
}
