import { CARD_EFFECT_TYPES } from 'common/consts/types/card-effect.js';

import { BaseCardEffect } from '../base.js';

export class IncreaseCardLimitCardEffect extends BaseCardEffect {
  constructor(params) {
    super(params);

    this.type = CARD_EFFECT_TYPES.INCREASE_CARD_LIMIT;
  }

  execute({ kingdom }) {
    kingdom.increaseCardsLimit(1);
  }
}
