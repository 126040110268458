import { Assets, BitmapText, Container, Graphics, Rectangle, Sprite, Text, Texture, TilingSprite } from 'pixi.js';

import cardBack from 'client/assets/images/cards/back.png';
import cardBackground from 'client/assets/images/cards/background.png';
import cardImageBackground from 'client/assets/images/cards/image-background.png';
import activateCannons from 'client/assets/images/cards/images/buildings/activate-cannons.png';
import destroyBuilding from 'client/assets/images/cards/images/buildings/destroy-building.png';
import improveHeadquarter from 'client/assets/images/cards/images/buildings/improve-headquarter.png';
import placeBasicCannon from 'client/assets/images/cards/images/buildings/place-basic-cannon.png';
import placeDefender from 'client/assets/images/cards/images/buildings/place-defender.png';
import placeForge from 'client/assets/images/cards/images/buildings/place-forge.png';
import placeMine from 'client/assets/images/cards/images/buildings/place-mine.png';
import placeMinesRandomly from 'client/assets/images/cards/images/buildings/place-mines-randomly.png';
import placePowerStation from 'client/assets/images/cards/images/buildings/place-power-station.png';
import placeRandomCannonsRandomly from 'client/assets/images/cards/images/buildings/place-random-cannons-randomly.png';
import placeShortRangeCannon from 'client/assets/images/cards/images/buildings/place-short-range-cannon.png';
import placeSniperCannon from 'client/assets/images/cards/images/buildings/place-sniper-cannon.png';
import placeWarehouse from 'client/assets/images/cards/images/buildings/place-warehouse.png';
import draw2Cards from 'client/assets/images/cards/images/cards/draw-2-cards.png';
import increaseCardLimit from 'client/assets/images/cards/images/cards/increase-card-limit.png';
import increaseMultishot from 'client/assets/images/cards/images/cards/increase-multishot.png';
import increasePower from 'client/assets/images/cards/images/cards/increase-power.png';
import replaceHand from 'client/assets/images/cards/images/cards/replace-hand.png';
import trashAndCopyCard from 'client/assets/images/cards/images/cards/trash-and-copy-card.png';
import trashCard from 'client/assets/images/cards/images/cards/trash-card.png';
import destroyField from 'client/assets/images/cards/images/effects/destroy-field.png';
import poisonToAll from 'client/assets/images/cards/images/effects/poison-to-all.png';
import voidToAll from 'client/assets/images/cards/images/effects/void-to-all.png';
import voidCardImage from 'client/assets/images/cards/images/effects/void.png';
import addEnergy from 'client/assets/images/cards/images/energy/add-energy.png';
import increaseEnergyProduction from 'client/assets/images/cards/images/energy/increase-energy-production.png';
import investment from 'client/assets/images/cards/images/energy/investment.png';
import reduceDrainRandomly from 'client/assets/images/cards/images/energy/reduce-drain-randomly.png';
import spark from 'client/assets/images/cards/images/energy/spark.png';
import fortificateAndPlaceReinforcement from 'client/assets/images/cards/images/fortifications/fortificate-and-place-reinforcement.png';
import fortificateAroundHeadquarter from 'client/assets/images/cards/images/fortifications/fortificate-around-headquarter.png';
import fortificateBorders from 'client/assets/images/cards/images/fortifications/fortificate-borders.png';
import fortificateBuildings from 'client/assets/images/cards/images/fortifications/fortificate-buildings.png';
import fortificateHeadquarterAndAdjacents from 'client/assets/images/cards/images/fortifications/fortificate-headquarter-and-adjacents.png';
import fortificateOwnField from 'client/assets/images/cards/images/fortifications/fortificate-own-field.png';
import randomFortifications from 'client/assets/images/cards/images/fortifications/random-fortifications.png';
import junk from 'client/assets/images/cards/images/junks/junk.png';
import energyToBullets from 'client/assets/images/cards/images/shots/energy-to-bullets.png';
import fairShot from 'client/assets/images/cards/images/shots/fair-shot.png';
import multishot from 'client/assets/images/cards/images/shots/multishot.png';
import powerShot from 'client/assets/images/cards/images/shots/power-shot.png';
import singleFire from 'client/assets/images/cards/images/shots/single-fire.png';
import sniperFire from 'client/assets/images/cards/images/shots/sniper-fire.png';
import sniperShotImproving from 'client/assets/images/cards/images/shots/sniper-shot-improving.png';
import spreadShotImproving from 'client/assets/images/cards/images/shots/spread-shot-improving.png';
import spreadShot from 'client/assets/images/cards/images/shots/spread-shot.png';
import cardOverlay from 'client/assets/images/cards/overlay.png';
import cardSelection from 'client/assets/images/cards/selection.png';
import spinner from 'client/assets/images/cards/spinner.png';
import poison from 'client/assets/images/effects/poison.png';
import voidIcon from 'client/assets/images/effects/void.png';
import backgroundTile from 'client/assets/images/entities/background-tile.png';
import bullet from 'client/assets/images/entities/bullet.png';
import emptyDeck from 'client/assets/images/entities/empty-deck.png';
import fieldSelector from 'client/assets/images/entities/field-selector.png';
import field from 'client/assets/images/entities/field.png';
import smallField from 'client/assets/images/entities/small-field.png';
import target from 'client/assets/images/entities/target.png';
import wall from 'client/assets/images/entities/wall.png';
import emptyDrawDot from 'client/assets/images/icons/empty-draw-dot.png';
import emptyDrawRect from 'client/assets/images/icons/empty-draw-rect.png';
import energy from 'client/assets/images/icons/energy.png';
import fortificationIcon from 'client/assets/images/icons/fortification.png';
import fullDrawDot from 'client/assets/images/icons/full-draw-dot.png';
import fullDrawRect from 'client/assets/images/icons/full-draw-rect.png';
import hexagon from 'client/assets/images/icons/hexagon.png';
import closeButton from 'client/assets/images/interface/buttons/close.png';
import hexButton from 'client/assets/images/interface/buttons/hex.png';
import fixedCardsButtonIcon from 'client/assets/images/interface/buttons/icons/fixed-cards.png';
import headquarterButtonIcon from 'client/assets/images/interface/buttons/icons/headquarter.png';
import randomCardsButtonIcon from 'client/assets/images/interface/buttons/icons/random-cards.png';
import targetButtonIcon from 'client/assets/images/interface/buttons/icons/target.png';
import leftButton from 'client/assets/images/interface/buttons/left.png';
import smallCancelButtonHovered from 'client/assets/images/interface/buttons/small-cancel-hovered.png';
import smallCancelButton from 'client/assets/images/interface/buttons/small-cancel.png';
import standardButtonHovered from 'client/assets/images/interface/buttons/standard-hovered.png';
import standardButton from 'client/assets/images/interface/buttons/standard.png';
import scrollbarBottomButton from 'client/assets/images/interface/scrollbar/bottom-button.png';
import scrollbarThumbBottom from 'client/assets/images/interface/scrollbar/thumb-bottom.png';
import scrollbarThumbCenter from 'client/assets/images/interface/scrollbar/thumb-center.png';
import scrollbarThumbHandler from 'client/assets/images/interface/scrollbar/thumb-handler.png';
import scrollbarThumbTop from 'client/assets/images/interface/scrollbar/thumb-top.png';
import scrollbarTopButton from 'client/assets/images/interface/scrollbar/top-button.png';
import scrollbarTrack from 'client/assets/images/interface/scrollbar/track.png';
import sliderEdge from 'client/assets/images/interface/slider/edge.png';
import sliderFilling from 'client/assets/images/interface/slider/filling.png';
import sliderHandle from 'client/assets/images/interface/slider/handle.png';
import basicCannon from 'client/assets/images/map-objects/buildings/cannons/basic.png';
import defenderCannon from 'client/assets/images/map-objects/buildings/cannons/defender.png';
import randomCannon from 'client/assets/images/map-objects/buildings/cannons/random.png';
import shortRangeCannon from 'client/assets/images/map-objects/buildings/cannons/short-range.png';
import sniperCannon from 'client/assets/images/map-objects/buildings/cannons/sniper.png';
import forge from 'client/assets/images/map-objects/buildings/forge.png';
import headquarterBuilding from 'client/assets/images/map-objects/buildings/headquarter.png';
import powerPlant from 'client/assets/images/map-objects/buildings/power-plant.png';
import reinforcement from 'client/assets/images/map-objects/buildings/reinforcement.png';
import warehouse from 'client/assets/images/map-objects/buildings/warehouse.png';
import mine from 'client/assets/images/map-objects/mine.png';
import buildingAura from 'client/assets/images/particles/building-aura.png';
import hexagonalParticle from 'client/assets/images/particles/hexagonal.png';

import { IMAGES } from 'common/consts/types/images.js';

export class TexturesManager {
  constructor(game) {
    this.game = game;

    this.textures = {};
  }

  loadAll = async () => {
    await Promise.all([
      this.registerBuildings(),
      this.registerEntities(),
      this.registerParticles(),
      this.registerCards(),
      this.registerIcons(),
      this.registerInterface(),
    ]);
  };

  registerBuildings = async () => {
    await Promise.all([
      this.registerTexture(IMAGES.STRUCTURES.BASIC_CANNON, basicCannon),
      this.registerTexture(IMAGES.STRUCTURES.HEADQUARTER, headquarterBuilding),
      this.registerTexture(IMAGES.STRUCTURES.RANDOM_CANNON, randomCannon),
      this.registerTexture(IMAGES.STRUCTURES.SHORT_RANGE_CANNON, shortRangeCannon),
      this.registerTexture(IMAGES.STRUCTURES.SNIPER_CANNON, sniperCannon),
      this.registerTexture(IMAGES.STRUCTURES.DEFENDER, defenderCannon),

      this.registerTexture(IMAGES.STRUCTURES.WAREHOUSE, warehouse),
      this.registerTexture(IMAGES.STRUCTURES.POWER_STATION, powerPlant),
      this.registerTexture(IMAGES.STRUCTURES.FORGE, forge),
      this.registerTexture(IMAGES.STRUCTURES.REINFORCEMENT, reinforcement),
      this.registerTexture(IMAGES.STRUCTURES.MINE, mine),
    ]);
  };

  registerEntities = async () => {
    await Promise.all([
      this.registerTexture(IMAGES.ENTITIES.BULLET, bullet),
      this.registerTexture(IMAGES.ENTITIES.EMPTY_DECK, emptyDeck),
      this.registerTexture(IMAGES.ENTITIES.TARGET, target),
      this.registerTexture(IMAGES.ENTITIES.FIELD_SELECTOR, fieldSelector),
      this.registerTexture(IMAGES.ENTITIES.FIELD, field),
      this.registerTexture(IMAGES.ENTITIES.SMALL_FIELD, smallField),
      this.registerTexture(IMAGES.ENTITIES.WALL, wall),
      this.registerTexture(IMAGES.ENTITIES.BACKGROUND_TILE, backgroundTile),
    ]);
  };

  registerParticles = async () => {
    await Promise.all([
      this.registerTexture(IMAGES.PARTICLES.BUILDING_AURA, buildingAura),
      this.registerTexture(IMAGES.PARTICLES.HEXAGONAL, hexagonalParticle),
    ]);
  };

  registerCards = async () => {
    await Promise.all([
      this.registerTexture(IMAGES.CARD_TYPES.BACKGROUND, cardBackground),
      this.registerTexture(IMAGES.CARD_TYPES.IMAGE_BACKGROUND, cardImageBackground),
      this.registerTexture(IMAGES.CARD_TYPES.BACK, cardBack),
      this.registerTexture(IMAGES.CARD_TYPES.OVERLAY, cardOverlay),
      this.registerTexture(IMAGES.CARD_TYPES.SELECTION, cardSelection),
      this.registerTexture(IMAGES.CARD_TYPES.SPINNER, spinner),

      this.registerTexture(IMAGES.CARD_TYPES.IMAGES.PLACE_BASIC_CANNON, placeBasicCannon),
      this.registerTexture(IMAGES.CARD_TYPES.IMAGES.PLACE_SHORT_RANGE_CANNON, placeShortRangeCannon),
      this.registerTexture(IMAGES.CARD_TYPES.IMAGES.PLACE_RANDOM_CANNONS_RANDOMLY, placeRandomCannonsRandomly),
      this.registerTexture(IMAGES.CARD_TYPES.IMAGES.PLACE_SNIPER_CANNON, placeSniperCannon),
      this.registerTexture(IMAGES.CARD_TYPES.IMAGES.PLACE_DEFENDER, placeDefender),
      this.registerTexture(IMAGES.CARD_TYPES.IMAGES.PLACE_WAREHOUSE, placeWarehouse),
      this.registerTexture(IMAGES.CARD_TYPES.IMAGES.PLACE_MINE, placeMine),
      this.registerTexture(IMAGES.CARD_TYPES.IMAGES.PLACE_MINES_RANDOMLY, placeMinesRandomly),
      this.registerTexture(IMAGES.CARD_TYPES.IMAGES.PLACE_POWER_STATION, placePowerStation),
      this.registerTexture(IMAGES.CARD_TYPES.IMAGES.PLACE_FORGE, placeForge),

      this.registerTexture(IMAGES.CARD_TYPES.IMAGES.IMPROVE_HEADQUARTER, improveHeadquarter),
      this.registerTexture(IMAGES.CARD_TYPES.IMAGES.ACTIVATE_CANNONS, activateCannons),

      this.registerTexture(IMAGES.CARD_TYPES.IMAGES.DESTROY_BUILDING, destroyBuilding),

      this.registerTexture(IMAGES.CARD_TYPES.IMAGES.ADD_ENERGY, addEnergy),
      this.registerTexture(IMAGES.CARD_TYPES.IMAGES.SPARK, spark),
      this.registerTexture(IMAGES.CARD_TYPES.IMAGES.INCREASE_ENERGY_PRODUCTION, increaseEnergyProduction),
      this.registerTexture(IMAGES.CARD_TYPES.IMAGES.REDUCE_DRAIN_RANDOMLY, reduceDrainRandomly),
      this.registerTexture(IMAGES.CARD_TYPES.IMAGES.INVESTMENT, investment),

      this.registerTexture(IMAGES.CARD_TYPES.IMAGES.TRASH_CARD, trashCard),
      this.registerTexture(IMAGES.CARD_TYPES.IMAGES.TRASH_AND_COPY_CARD, trashAndCopyCard),

      this.registerTexture(IMAGES.CARD_TYPES.IMAGES.DRAW_CARDS, draw2Cards),
      this.registerTexture(IMAGES.CARD_TYPES.IMAGES.INCREASE_POWER, increasePower),
      this.registerTexture(IMAGES.CARD_TYPES.IMAGES.INCREASE_MULTISHOT, increaseMultishot),
      this.registerTexture(IMAGES.CARD_TYPES.IMAGES.INCREASE_CARD_LIMIT, increaseCardLimit),
      this.registerTexture(IMAGES.CARD_TYPES.IMAGES.REPLACE_HAND, replaceHand),

      this.registerTexture(IMAGES.CARD_TYPES.IMAGES.SPREAD_SHOT, spreadShot),
      this.registerTexture(IMAGES.CARD_TYPES.IMAGES.SPREAD_SHOT_IMPROVING, spreadShotImproving),
      this.registerTexture(IMAGES.CARD_TYPES.IMAGES.SINGLE_FIRE, singleFire),
      this.registerTexture(IMAGES.CARD_TYPES.IMAGES.SNIPER_SHOT_IMPROVING, sniperShotImproving),
      this.registerTexture(IMAGES.CARD_TYPES.IMAGES.SNIPER_FIRE, sniperFire),
      this.registerTexture(IMAGES.CARD_TYPES.IMAGES.MULTISHOT, multishot),
      this.registerTexture(IMAGES.CARD_TYPES.IMAGES.ENERGY_TO_BULLETS, energyToBullets),
      this.registerTexture(IMAGES.CARD_TYPES.IMAGES.FAIR_SHOT, fairShot),
      this.registerTexture(IMAGES.CARD_TYPES.IMAGES.POWER_SHOT, powerShot),

      this.registerTexture(IMAGES.CARD_TYPES.IMAGES.POISON_TO_ALL, poisonToAll),
      this.registerTexture(IMAGES.CARD_TYPES.IMAGES.VOID, voidCardImage),
      this.registerTexture(IMAGES.CARD_TYPES.IMAGES.VOID_TO_ALL, voidToAll),
      this.registerTexture(IMAGES.CARD_TYPES.IMAGES.DESTROY_FIELD, destroyField),

      this.registerTexture(IMAGES.CARD_TYPES.IMAGES.RANDOM_FORTIFICATIONS, randomFortifications),
      this.registerTexture(IMAGES.CARD_TYPES.IMAGES.FORTIFICATE_OWN_FIELD, fortificateOwnField),
      this.registerTexture(IMAGES.CARD_TYPES.IMAGES.FORTIFICATE_BORDERS, fortificateBorders),
      this.registerTexture(IMAGES.CARD_TYPES.IMAGES.FORTIFICATE_HEADQUARTER_AND_ADJACENTS, fortificateHeadquarterAndAdjacents),
      this.registerTexture(IMAGES.CARD_TYPES.IMAGES.FORTIFICATE_AROUND_HEADQUARTER, fortificateAroundHeadquarter),
      this.registerTexture(IMAGES.CARD_TYPES.IMAGES.FORTIFICATE_STRUCTURES, fortificateBuildings),
      this.registerTexture(IMAGES.CARD_TYPES.IMAGES.FORTIFICATE_AND_PLACE_REINFORCEMENT, fortificateAndPlaceReinforcement),

      this.registerTexture(IMAGES.CARD_TYPES.IMAGES.JUNK, junk),
    ]);
  };

  registerIcons = async () => {
    await Promise.all([
      this.registerTexture(IMAGES.ICONS.ENERGY, energy),
      this.registerTexture(IMAGES.ICONS.HEXAGON, hexagon),

      this.registerTexture(IMAGES.ICONS.FORTIFICATION, fortificationIcon),

      this.registerTexture(IMAGES.ICONS.EMPTY_DRAW_DOT, emptyDrawDot),
      this.registerTexture(IMAGES.ICONS.FULL_DRAW_DOT, fullDrawDot),
      this.registerTexture(IMAGES.ICONS.EMPTY_DRAW_RECT, emptyDrawRect),
      this.registerTexture(IMAGES.ICONS.FULL_DRAW_RECT, fullDrawRect),

      this.registerTexture(IMAGES.ICONS.POISON, poison),
      this.registerTexture(IMAGES.ICONS.VOID, voidIcon),
    ]);
  };

  registerInterface = async () => {
    await Promise.all([
      this.registerTexture(IMAGES.INTERFACE.BUTTONS.HEX, hexButton),

      this.registerTexture(IMAGES.INTERFACE.SCROLLBAR.TOP_BUTTON, scrollbarTopButton),
      this.registerTexture(IMAGES.INTERFACE.SCROLLBAR.BOTTOM_BUTTON, scrollbarBottomButton),
      this.registerTexture(IMAGES.INTERFACE.SCROLLBAR.THUMB_HANDLER, scrollbarThumbHandler),
      this.registerTexture(IMAGES.INTERFACE.SCROLLBAR.THUMB_TOP, scrollbarThumbTop),
      this.registerTexture(IMAGES.INTERFACE.SCROLLBAR.THUMB_CENTER, scrollbarThumbCenter),
      this.registerTexture(IMAGES.INTERFACE.SCROLLBAR.THUMB_BOTTOM, scrollbarThumbBottom),
      this.registerTexture(IMAGES.INTERFACE.SCROLLBAR.TRACK, scrollbarTrack),

      this.registerTexture(IMAGES.INTERFACE.SLIDER.EDGE, sliderEdge),
      this.registerTexture(IMAGES.INTERFACE.SLIDER.HANDLE, sliderHandle),
      this.registerTexture(IMAGES.INTERFACE.SLIDER.FILLING, sliderFilling),

      this.registerTexture(IMAGES.INTERFACE.BUTTONS.CLOSE, closeButton),
      this.registerTexture(IMAGES.INTERFACE.BUTTONS.LEFT, leftButton),

      this.registerTexture(IMAGES.INTERFACE.BUTTONS.STANDARD, standardButton),
      this.registerTexture(IMAGES.INTERFACE.BUTTONS.STANDARD_HOVERED, standardButtonHovered),
      this.registerTexture(IMAGES.INTERFACE.BUTTONS.SMALL_CANCEL, smallCancelButton),
      this.registerTexture(IMAGES.INTERFACE.BUTTONS.SMALL_CANCEL_HOVERED, smallCancelButtonHovered),

      this.registerTexture(IMAGES.INTERFACE.BUTTONS.ICONS.TARGET, targetButtonIcon),
      this.registerTexture(IMAGES.INTERFACE.BUTTONS.ICONS.HEADQUARTER, headquarterButtonIcon),
      this.registerTexture(IMAGES.INTERFACE.BUTTONS.ICONS.FIXED_CARDS, fixedCardsButtonIcon),
      this.registerTexture(IMAGES.INTERFACE.BUTTONS.ICONS.RANDOM_CARDS, randomCardsButtonIcon),
    ]);
  };

  registerTexture = async (id, image) => {
    await Assets.load(image);
    this.textures[id] = Texture.from(image, { anisotropicLevel: 16 });
  };

  getTexture(id) {
    return this.textures[id];
  }

  createStandardSprite(textureId) {
    const sprite = this.createSprite(textureId);
    sprite.anchor.set(0.5);

    return sprite;
  }

  createOutlinedText(text = '', params = {}) {
    return new BitmapText({
      text,
      style: {
        fontFamily: 'Arial',
        fontSize: 20,
        fill: 0xffffff,
        letterSpacing: 1,
        stroke: {
          width: 5.5,
          color: '#444444',
          join: 'round',
        },
        ...params,
      },
    });
  }

  createSprite(textureId) {
    return new Sprite(this.getTexture(textureId));
  }

  createEmptySprite() {
    return new Sprite();
  }

  createTillingSprite(textureId, width, height) {
    return new TilingSprite({ texture: this.getTexture(textureId), width, height });
  }

  createContainer() {
    const container = new Container();
    container.zIndex = 1;

    return container;
  }

  createText(text, params) {
    return new BitmapText({ text, style: { fontFamily: 'Arial', fontSize: 30, fill: 0x222222, ...params } });
  }

  createHTMLText(text, params) {
    return new Text({
      text,
      style: { fontFamily: 'Arial', fontSize: 30, fill: 0x222222, ...params },
    });
  }

  graphicIntoTexture(graphic) {
    return this.game.renderer.generateTexture(graphic);
  }

  generateBoxSprite(params) {
    const sprite = new Sprite();

    sprite.texture = this.getBoxTexture(params);

    return sprite;
  }

  getBoxTexture(params) {
    const { height, width, borderRadius = 0, borderWidth = 0, borderColor, color } = params;

    const borderlessHeight = height - borderWidth * 2;
    const borderlessWidth = width - borderWidth * 2;

    const graphic = new Graphics();

    graphic.roundRect(borderlessWidth / 2, -borderlessHeight / 2, borderlessWidth, borderlessHeight, borderRadius);
    if (borderWidth) graphic.stroke({ width: borderWidth, color: borderColor });
    graphic.fill({ color });

    return this.graphicIntoTexture(graphic);
  }

  addDebugBackgroundToContainer(container) {
    const sprite = new Sprite(Texture.WHITE);

    sprite.height = container.height;
    sprite.width = container.width;
    sprite.anchor.set(0.5, 0.5);

    container.addChild(sprite);
  }
}
