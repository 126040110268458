import { SOUNDS } from 'client/consts/sounds.js';
import { Z_INDEX } from 'client/consts/z-index.js';
import { Box } from 'client/interface/elements/box.js';
import { Button } from 'client/interface/elements/button.js';
import { OptionSwitcher } from 'client/interface/elements/option-switcher.js';
import { Overlay } from 'client/interface/elements/overlay.js';
import { Slider } from 'client/interface/elements/slider.js';
import { Text } from 'client/interface/elements/text.js';
import { CentredInterfaceGroup } from 'client/interface/groups/centred-interface-group.js';

import { TEXT_TYPES } from 'common/languages/text-types.js';

const MARGIN = 20;
const GAP = 5;
const PADDING = 10;

const TITLE_SIZE = 40;
const TEXT_SIZE = 20;

const WIDTH = 450;

export class SettingsMenu extends CentredInterfaceGroup {
  constructor(params) {
    super(params);

    this.width = WIDTH;

    this.gap = GAP;
    this.padding = PADDING;

    this.createSubElements();
    this.setTexts();
    this.registerEventListeners();
  }

  toggle() {
    this.visible ? this.close() : this.show();
  }

  open() {
    this.setElementsPositions();
    this.setBoxSize();
    this.setVisibility(true);
  }

  close() {
    this.setVisibility(false);
  }

  registerEventListeners() {
    this.game.eventsController.addListener('escKeyDown', this);
  }

  onEscKeyDown() {
    if (!this.visible) return false;

    this.close();
    this.game.interfaceController.pauseMenu.open();

    return true;
  }

  createSubElements() {
    this.registerElement(
      'overlay',
      new Overlay({
        game: this.game,
        zIndex: Z_INDEX.MESSAGE_BOX,
      })
    );

    this.registerElement(
      'box',
      new Box({
        game: this.game,
        zIndex: Z_INDEX.MESSAGE_BOX,
      })
    );

    this.registerElement(
      'title',
      new Text({
        game: this.game,
        zIndex: Z_INDEX.MESSAGE_BOX,
        width: this.width,
        fontSize: TITLE_SIZE,
      })
    );

    this.registerElement(
      'soundLabel',
      new Text({
        game: this.game,
        zIndex: Z_INDEX.MESSAGE_BOX,
        width: this.width,
        fontSize: TEXT_SIZE,
        marginTop: MARGIN,
      })
    );

    this.registerElement(
      'soundSlider',
      new Slider({
        startingValue: this.game.settingsController.getSoundVolume(),
        onChange: this.onSoundBarSlide,
        game: this.game,
        zIndex: Z_INDEX.MESSAGE_BOX,
        width: this.width,
      })
    );

    this.registerElement(
      'languageLabel',
      new Text({
        game: this.game,
        zIndex: Z_INDEX.MESSAGE_BOX,
        width: this.width,
        fontSize: TEXT_SIZE,
        marginTop: MARGIN / 2,
      })
    );

    this.registerElement(
      'languageInput',
      new OptionSwitcher({
        game: this.game,
        zIndex: Z_INDEX.MESSAGE_BOX,
        width: this.width,
        fontSize: TEXT_SIZE,
        marginTop: MARGIN / 2,
        options: this.game.settingsController.getLanguageOptions(),
        startingOption: this.game.settingsController.getLanguage(),
        onChange: this.onLanguageChange,
      })
    );

    this.registerElement(
      'resumeButton',
      new Button({
        text: this.game.textsController.getInterfaceText(TEXT_TYPES.BACK),
        callback: this.onBack,
        game: this.game,
        zIndex: Z_INDEX.MESSAGE_BOX,
        marginTop: MARGIN,
      })
    );
  }

  setTexts() {
    this.elements.title.setText(this.game.textsController.getInterfaceText(TEXT_TYPES.SETTINGS));
    this.elements.soundLabel.setText(this.game.textsController.getInterfaceText(TEXT_TYPES.SOUND_VOLUME) + ':');
    this.elements.languageLabel.setText(this.game.textsController.getInterfaceText(TEXT_TYPES.LANGUAGE) + ':');
    this.elements.resumeButton.setText(this.game.textsController.getInterfaceText(TEXT_TYPES.BACK));
  }

  setBoxSize() {
    this.elements.box.setSize(this.width, this.height);
  }

  onSoundBarSlide = (value) => {
    this.game.settingsController.setSoundVolume(value);
    this.game.soundsController.playSound(SOUNDS.SELECTING_CARD, { soundIndex: 2 });
  };

  onLanguageChange = (language) => {
    this.game.settingsController.setLanguage(language);
  };

  onBack = () => {
    this.close();
    this.game.interfaceController.pauseMenu.open();
  };

  afterResize() {
    if (!this.visible) return false;

    this.setElementsPositions();
  }

  regenerateTexts() {
    this.setTexts();
  }
}
