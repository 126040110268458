import { CARD_EFFECT_TYPES } from 'common/consts/types/card-effect.js';

import { randomElement } from 'common/helpers/array.js';
import { perFrameTimer } from 'common/helpers/converters.js';
import { randomIn } from 'common/helpers/random.js';

import { Kingdom } from '../kingdom.js';

const PLAY_TIMER = perFrameTimer(0.4);

export class EnemyKingdom extends Kingdom {
  constructor(params) {
    super(params);

    this.playTimer = PLAY_TIMER;
    this.isAssigned = true;
    this.cyclesCount = 0;
  }

  activate(delta) {
    if (this.eliminated) return false;

    super.activate(delta);
    this.calculatePlayTimer(delta);
  }

  onCycleEnd() {
    if (this.eliminated) return false;

    super.onCycleEnd();

    this.pickNewTarget();
    this.tryRedraw();
    this.increaseCyclesCount();
  }

  calculatePlayTimer(delta) {
    this.playTimer -= delta;

    if (this.playTimer > 0) return false;

    this.playTimer = PLAY_TIMER;

    this.onPlayTimer();
  }

  onPlayTimer() {}

  pickNewTarget() {
    const allKingdoms = this.game.kingdomsController.returnKingdoms();
    const enemyKingdoms = allKingdoms.filter((kingdom) => kingdom.id !== this.id && kingdom.getHeadquarter());

    if (enemyKingdoms.length > 0) {
      const targetKingdom = randomIn(enemyKingdoms);
      this.changeTarget(targetKingdom.getHeadquarter().getPosition().clone());
    } else {
      this.changeTarget(this.game.mapController.getCenterHexPosition().toPoint());
    }
  }

  tryPlayCheapestCard() {
    const cards = this.hand.sort((cardA, cardB) => cardA.cost() - cardB.cost());
    const cheapestCard = cards[0];

    if (!cheapestCard) return;

    this.tryUseCard(cheapestCard);
  }

  tryPlayRandom() {
    const card = randomElement(this.hand);

    if (!card) return;

    const result = this.tryUseCard(card);

    if (result) return;

    this.discardCard(card);
  }

  tryUseCard = async (card) => {
    const checkResult = this.game.cardEffectsManager.canBePlayed(card, this);

    if (!checkResult.can) return false;

    const targets = await this.game.cardEffectsManager.pickTargetsForCard(card, this);

    if (targets.destroyed) return false;
    if (targets.cancel) return false;

    this.game.cardEffectsManager.playCard(card.id, targets, this);

    this.discardFromPlay(card);
  };

  orderPickingShotTargetField() {
    return { fieldId: this.getTargetField().id };
  }

  orderPickingFieldForBuilding() {
    const closestField = this.getClosestOwnEmptyField();

    if (!closestField) return { cancel: true };
    return { fieldId: closestField.id };
  }

  orderPickingOwnBuildingToDestroy() {
    return { cancel: true };
  }

  orderPickingOwnFieldToFortificate() {
    return { fieldId: this.getHeadquarter().getField().id };
  }

  increaseCyclesCount() {
    this.cyclesCount++;

    if (this.cyclesCount === 30) this.after30Cycles();
    if (this.cyclesCount === 60) this.after60Cycles();
    if (this.cyclesCount === 90) this.after90Cycles();
  }

  getTargetField() {
    return this.game.mapController.getFieldByHex(this.target.toHex());
  }

  getClosestOwnEmptyField() {
    const fields = this.game.mapController.getAllFields().filter((field) => field.ownedBy(this) && field.empty());

    fields.forEach((field) => field.saveDistanceFor(this.headquarter.position));
    fields.sort((fieldA, fieldB) => fieldA.distance - fieldB.distance);

    return fields[0];
  }

  createCard(params) {
    return this.game.cardsCreator.createCard({ ...params, hideInterface: true, owner: this });
  }

  after30Cycles() {}
  after60Cycles() {}
  after90Cycles() {}
}
