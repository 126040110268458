export function random(min, max) {
  const randomPart = Math.round((max - min) * Math.random());
  return randomPart + min;
}

export function randomDecimal(min, max) {
  const randomPart = (max - min) * Math.random();
  return randomPart + min;
}

export function randomIn(array) {
  return array[random(0, array.length - 1)];
}

export function randomBoolean() {
  return Math.random() >= 0.5;
}

export function getChance(chance) {
  return Math.random() <= chance;
}

export function randomString(length = 10) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const max = characters.length - 1;

  for (let i = 0; i < length; i++) {
    result += characters.charAt(random(0, max));
  }

  return result;
}
