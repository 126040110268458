import { TOOLTIP } from 'common/consts/tooltip.js';
import { CARD_EFFECT_TYPES } from 'common/consts/types/card-effect.js';
import { ENTITIES } from 'common/consts/types/entities.js';

import { BaseCardEffect } from '../base.js';

export class FortificateAroundHeadquarterCardEffect extends BaseCardEffect {
  constructor(params) {
    super(params);

    this.type = CARD_EFFECT_TYPES.FORTIFICATE_AROUND_HEADQUARTER;
  }

  getTooltipTypes() {
    return [TOOLTIP.TYPES.FORTIFICATIONS];
  }

  execute({ kingdom, card }) {
    const { fortifications } = card.getStats();

    const headquarter = kingdom.getHeadquarter();
    const mainField = headquarter.getFieldByHex();

    const fields = mainField.allAdjacentFields().filter((field) => field && field.ownedBy(kingdom) && field.isType(ENTITIES.FIELD));

    for (let i = 0; i < fields.length; i++) {
      fields[i].changeFortifications(fortifications);
    }
  }
}
