import { COLORS } from 'common/consts/colors.js';
import { CARD_EFFECT_TYPES } from 'common/consts/types/card-effect.js';
import { CARD_TYPES } from 'common/consts/types/card.js';
import { IMAGES } from 'common/consts/types/images.js';

export const STARTING_CARDS = {
  [CARD_TYPES.SPARK]: {
    type: CARD_TYPES.SPARK,
    image: IMAGES.CARD_TYPES.IMAGES.SPARK,
    effectTypes: [CARD_EFFECT_TYPES.ADD_ENERGY],
    stats: {
      energy: 1,
    },
    color: COLORS.CARD.GREEN,
  },
  [CARD_TYPES.SPREAD_SHOT]: {
    type: CARD_TYPES.SPREAD_SHOT,
    image: IMAGES.CARD_TYPES.IMAGES.SPREAD_SHOT,
    effectTypes: [CARD_EFFECT_TYPES.SHOT],
    stats: {
      spread: 10,
    },
    color: COLORS.CARD.RED,
  },
  [CARD_TYPES.SNIPER_FIRE]: {
    type: CARD_TYPES.SNIPER_FIRE,
    image: IMAGES.CARD_TYPES.IMAGES.SNIPER_FIRE,
    effectTypes: [CARD_EFFECT_TYPES.SHOT],
    stats: {
      inaccuracy: 0,
      shots: 5,
    },
    color: COLORS.CARD.RED,
  },
  [CARD_TYPES.FORTIFICATE_OWN_FIELD]: {
    type: CARD_TYPES.FORTIFICATE_OWN_FIELD,
    image: IMAGES.CARD_TYPES.IMAGES.FORTIFICATE_OWN_FIELD,
    effectTypes: [CARD_EFFECT_TYPES.FORTIFICATE_OWN_FIELD],
    stats: {
      fortifications: 5,
    },
    color: COLORS.CARD.BLUE,
  },
  [CARD_TYPES.RANDOM_FORTIFICATIONS]: {
    type: CARD_TYPES.RANDOM_FORTIFICATIONS,
    image: IMAGES.CARD_TYPES.IMAGES.RANDOM_FORTIFICATIONS,
    effectTypes: [CARD_EFFECT_TYPES.RANDOM_FORTIFICATIONS],
    stats: {
      fortifications: 2,
      fields: 5,
    },
    color: COLORS.CARD.BLUE,
  },
};
