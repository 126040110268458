import { CARD_TYPES } from 'common/consts/types/card.js';

import { EnemyKingdom } from '../enemy.js';

export class SpreadKingdom extends EnemyKingdom {
  onPlayTimer() {
    this.tryPlayRandom();
  }

  startingCards() {
    const cards = [
      this.createCard({ type: CARD_TYPES.ENERGY_TO_BULLETS }),
      this.createCard({ type: CARD_TYPES.PLACE_POWER_STATION }),
      this.createCard({ type: CARD_TYPES.PLACE_POWER_STATION }),
      this.createCard({ type: CARD_TYPES.PLACE_POWER_STATION }),
      this.createCard({ type: CARD_TYPES.PLACE_POWER_STATION }),
      this.createCard({ type: CARD_TYPES.ADD_ENERGY }),
      this.createCard({ type: CARD_TYPES.ADD_ENERGY }),
      this.createCard({ type: CARD_TYPES.ADD_ENERGY }),
      this.createCard({ type: CARD_TYPES.FORTIFICATE_HEADQUARTER_AND_ADJACENTS }),
      this.createCard({ type: CARD_TYPES.FORTIFICATE_HEADQUARTER_AND_ADJACENTS }),
      this.createCard({ type: CARD_TYPES.FORTIFICATE_HEADQUARTER_AND_ADJACENTS }),
      this.createCard({ type: CARD_TYPES.RANDOM_FORTIFICATIONS }),
      this.createCard({ type: CARD_TYPES.RANDOM_FORTIFICATIONS }),
    ];

    if (this.difficulty >= 2) cards.push(this.createCard({ type: CARD_TYPES.PLACE_POWER_STATION }));
    if (this.difficulty >= 2) cards.push(this.createCard({ type: CARD_TYPES.PLACE_POWER_STATION }));
    if (this.difficulty >= 2) cards.push(this.createCard({ type: CARD_TYPES.PLACE_POWER_STATION }));
    if (this.difficulty >= 2) cards.push(this.createCard({ type: CARD_TYPES.PLACE_POWER_STATION }));
    if (this.difficulty >= 2) cards.push(this.createCard({ type: CARD_TYPES.DRAW_CARDS }));

    if (this.difficulty >= 3) cards.push(this.createCard({ type: CARD_TYPES.PLACE_POWER_STATION }));
    if (this.difficulty >= 3) cards.push(this.createCard({ type: CARD_TYPES.PLACE_POWER_STATION }));
    if (this.difficulty >= 3) cards.push(this.createCard({ type: CARD_TYPES.PLACE_POWER_STATION }));
    if (this.difficulty >= 3) cards.push(this.createCard({ type: CARD_TYPES.PLACE_POWER_STATION }));
    if (this.difficulty >= 3) cards.push(this.createCard({ type: CARD_TYPES.FORTIFICATE_AROUND_HEADQUARTER }));
    if (this.difficulty >= 3) cards.push(this.createCard({ type: CARD_TYPES.DRAW_CARDS }));

    return cards;
  }
}
