import { TOOLTIP } from 'common/consts/tooltip.js';
import { CARD_EFFECT_TYPES } from 'common/consts/types/card-effect.js';

import { BaseCardEffect } from '../base.js';

export class VoidToAllCardEffect extends BaseCardEffect {
  constructor(params) {
    super(params);

    this.type = CARD_EFFECT_TYPES.VOID_TO_ALL;
  }

  getTooltipTypes() {
    return [TOOLTIP.TYPES.VOID];
  }
  execute({ card }) {
    const { voidPower } = card.getStats();

    this.game.kingdomsController.eachKingdom((kingdom) => {
      kingdom.addEffect({ type: CARD_EFFECT_TYPES.VOID, power: voidPower });
    });
  }
}
