import { TOOLTIP } from 'common/consts/tooltip.js';
import { CARD_EFFECT_TYPES } from 'common/consts/types/card-effect.js';

import { BaseCardEffect } from '../base.js';

export class MultishotCardEffect extends BaseCardEffect {
  constructor(params) {
    super(params);

    this.type = CARD_EFFECT_TYPES.MULTISHOT;
  }

  getTooltipTypes({ power }) {
    const tooltipTypes = [];

    if (power > 1) tooltipTypes.push(TOOLTIP.TYPES.POWER);
    tooltipTypes.push(TOOLTIP.TYPES.MULTISHOT);
    tooltipTypes.push(TOOLTIP.TYPES.SPREAD);

    return tooltipTypes;
  }

  execute({ kingdom, card, targets }) {
    const { power, spread } = card.getStats();

    const headquarter = kingdom.getHeadquarter();

    const field = this.game.entitiesController.findById(targets.fieldId);
    headquarter.multishot({ power, spread, target: field.getPosition() });
  }

  pickTarget({ kingdom }) {
    return kingdom.orderPickingShotTargetField();
  }
}
