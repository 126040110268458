import { Z_INDEX } from 'client/consts/z-index.js';

import { IMAGES } from 'common/consts/types/images.js';

import { HexButtonSprite } from './hex.js';

export class RandomCardsButton extends HexButtonSprite {
  constructor(params) {
    super({ ...params, iconType: IMAGES.INTERFACE.BUTTONS.ICONS.RANDOM_CARDS, zIndex: Z_INDEX.CARDS_BUTTONS });

    this.setupSprites(params);
  }

  onClick = () => {
    this.game.eventsController.runEvent('randomCardsButtonClick');
  };

  updatePosition = () => {
    const x = window.innerWidth - this.getWidth();
    const y = window.innerHeight / 2 - this.getHeight() * 0.6;

    this.setPosition(x, y);
  };
}
