import { CARD_TYPES } from 'common/consts/types/card.js';

import { EnemyKingdom } from '../enemy.js';

export class EscalatorKingdom extends EnemyKingdom {
  onPlayTimer() {
    this.tryPlayRandom();
  }

  startingCards() {
    const cards = [
      this.createCard({ type: CARD_TYPES.SPREAD_SHOT_IMPROVING }),
      this.createCard({ type: CARD_TYPES.FORTIFICATE_HEADQUARTER_AND_ADJACENTS }),
      this.createCard({ type: CARD_TYPES.FORTIFICATE_HEADQUARTER_AND_ADJACENTS }),
    ];

    if (this.difficulty >= 2) cards.push(this.createCard({ type: CARD_TYPES.SPREAD_SHOT }));
    if (this.difficulty >= 2) cards.push(this.createCard({ type: CARD_TYPES.SPREAD_SHOT }));
    if (this.difficulty >= 2) cards.push(this.createCard({ type: CARD_TYPES.SPREAD_SHOT }));
    if (this.difficulty >= 2) cards.push(this.createCard({ type: CARD_TYPES.SPREAD_SHOT_IMPROVING }));
    if (this.difficulty >= 2) cards.push(this.createCard({ type: CARD_TYPES.RANDOM_FORTIFICATIONS }));

    if (this.difficulty >= 3) cards.push(this.createCard({ type: CARD_TYPES.SPREAD_SHOT }));
    if (this.difficulty >= 3) cards.push(this.createCard({ type: CARD_TYPES.SPREAD_SHOT }));
    if (this.difficulty >= 3) cards.push(this.createCard({ type: CARD_TYPES.SPREAD_SHOT_IMPROVING }));
    if (this.difficulty >= 3) cards.push(this.createCard({ type: CARD_TYPES.FORTIFICATE_AROUND_HEADQUARTER }));

    return cards;
  }
}
