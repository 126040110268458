export const POWER_PLANT_PRODUCTION = 1;
export const BASE_CANNONS_INACCURACY = 45;
export const DEFENDER_RANGE = 4;
export const DEFENDER_NUMBER_OF_BULLETS = 6;
export const MINE_DAMAGE = 3;
export const MINE_RANGE = 3;
export const RANDOM_CANNON_INACCURACY = 360;
export const SHORT_RANGE_CANNON_RANGE = 8;
export const FORGE_FORTIFICATION = 1;
export const REINFORCEMENET_HITS = 5;
export const HEADQUARTER_INACCURACY_REDUCTION = 0.15;
