import * as PIXI from 'pixi.js';

import { InterfaceElement } from 'client/interface/elements/interface-element.js';

import { IMAGES } from 'common/consts/types/images.js';

const FONT_SIZE = 20;

export class Button extends InterfaceElement {
  constructor(params) {
    super(params);

    this.callback = params.callback || '';
    this.text = params.text || '';
    this.marginTop = params.marginTop || 0;

    this.height = 50;
    this.width = 300;

    this.createSprites();
    this.setVisibility(params.visible || false);
  }

  setCallback(callback) {
    this.callback = callback;
  }

  setText(text) {
    this.text = text;
    this.sprites.text.text = text;
  }

  createSprites() {
    this.createContainer();
    this.createBox();
    this.createBoxHovered();
    this.createText();
  }

  createContainer() {
    this.sprites.container = new PIXI.Container();
    this.sprites.container.zIndex = this.zIndex;
    this.sprites.container.eventMode = 'static';
    this.sprites.container.cursor = 'pointer';

    this.sprites.container.on('pointerover', this.onMouseEnter);
    this.sprites.container.on('pointerout', this.onMouseLeave);
    this.sprites.container.on('pointerdown', this.onClick);

    this.registerSprite(this.sprites.container);
  }

  createBox() {
    this.sprites.box = this.game.texturesManager.createStandardSprite(IMAGES.INTERFACE.BUTTONS.STANDARD);
    this.sprites.box.anchor.set(0.5, 0.5);

    this.sprites.container.addChild(this.sprites.box);
  }

  createBoxHovered() {
    this.sprites.boxHovered = this.game.texturesManager.createStandardSprite(IMAGES.INTERFACE.BUTTONS.STANDARD_HOVERED);
    this.sprites.boxHovered.visible = false;
    this.sprites.boxHovered.anchor.set(0.5, 0.5);

    this.sprites.container.addChild(this.sprites.boxHovered);
  }

  onMouseEnter = () => {
    this.sprites.box.visible = false;
    this.sprites.boxHovered.visible = true;
  };

  onMouseLeave = () => {
    this.sprites.box.visible = true;
    this.sprites.boxHovered.visible = false;
  };

  onClick = () => {
    this.callback && this.callback();
  };

  createText() {
    this.sprites.text = this.game.texturesManager.createText(this.text, { fontSize: FONT_SIZE });
    this.sprites.text.anchor.set(0.5, 0.5);

    this.sprites.container.addChild(this.sprites.text);
  }
}
