import { CARD_TYPES } from 'common/consts/types/card.js';

import { EnemyKingdom } from '../enemy.js';

export class HeadquarterUpdaterKingdom extends EnemyKingdom {
  onPlayTimer() {
    this.tryPlayRandom();
    this.tryToBuyMainCard();
  }

  tryToBuyMainCard() {
    this.tryToBuy(CARD_TYPES.IMPROVE_HEADQUARTER);
  }

  startingCards() {
    const cards = [
      this.createCard({ type: CARD_TYPES.ADD_ENERGY }),
      this.createCard({ type: CARD_TYPES.ADD_ENERGY }),
      this.createCard({ type: CARD_TYPES.ADD_ENERGY }),
      this.createCard({ type: CARD_TYPES.ADD_ENERGY }),
      this.createCard({ type: CARD_TYPES.ADD_ENERGY }),
      this.createCard({ type: CARD_TYPES.ADD_ENERGY }),
      this.createCard({ type: CARD_TYPES.ADD_ENERGY }),
      this.createCard({ type: CARD_TYPES.ADD_ENERGY }),
      this.createCard({ type: CARD_TYPES.FORTIFICATE_HEADQUARTER_AND_ADJACENTS }),
      this.createCard({ type: CARD_TYPES.RANDOM_FORTIFICATIONS }),
      this.createCard({ type: CARD_TYPES.RANDOM_FORTIFICATIONS }),
      this.createCard({ type: CARD_TYPES.RANDOM_FORTIFICATIONS }),
      this.createCard({ type: CARD_TYPES.IMPROVE_HEADQUARTER }),
    ];

    if (this.difficulty >= 2) cards.push(this.createCard({ type: CARD_TYPES.ADD_ENERGY }));
    if (this.difficulty >= 2) cards.push(this.createCard({ type: CARD_TYPES.ADD_ENERGY }));
    if (this.difficulty >= 2) cards.push(this.createCard({ type: CARD_TYPES.DRAW_CARDS }));
    if (this.difficulty >= 2) cards.push(this.createCard({ type: CARD_TYPES.DRAW_CARDS }));
    if (this.difficulty >= 2) cards.push(this.createCard({ type: CARD_TYPES.DRAW_CARDS }));

    if (this.difficulty >= 3) cards.push(this.createCard({ type: CARD_TYPES.ADD_ENERGY }));
    if (this.difficulty >= 3) cards.push(this.createCard({ type: CARD_TYPES.ADD_ENERGY }));
    if (this.difficulty >= 3) cards.push(this.createCard({ type: CARD_TYPES.DRAW_CARDS }));
    if (this.difficulty >= 3) cards.push(this.createCard({ type: CARD_TYPES.DRAW_CARDS }));
    if (this.difficulty >= 3) cards.push(this.createCard({ type: CARD_TYPES.DRAW_CARDS }));

    return cards;
  }
}
