import { COLORS } from 'common/consts/colors.js';
import { CARD_EFFECT_TYPES } from 'common/consts/types/card-effect.js';
import { CARD_TYPES } from 'common/consts/types/card.js';
import { IMAGES } from 'common/consts/types/images.js';

export const RANDOM_CARDS = {
  [CARD_TYPES.INVESTMENT]: {
    type: CARD_TYPES.INVESTMENT,
    cost: 30,
    image: IMAGES.CARD_TYPES.IMAGES.INVESTMENT,
    effectTypes: [CARD_EFFECT_TYPES.DESTROY, CARD_EFFECT_TYPES.ADD_ENERGY],
    stats: {
      energy: 35,
    },
    color: COLORS.CARD.GREEN,
  },

  [CARD_TYPES.FORTIFICATE_AROUND_HEADQUARTER]: {
    type: CARD_TYPES.FORTIFICATE_AROUND_HEADQUARTER,
    cost: 5,
    image: IMAGES.CARD_TYPES.IMAGES.FORTIFICATE_AROUND_HEADQUARTER,
    effectTypes: [CARD_EFFECT_TYPES.LIGHTWEIGHT, CARD_EFFECT_TYPES.FORTIFICATE_AROUND_HEADQUARTER],
    stats: {
      fortifications: 1,
    },
    color: COLORS.CARD.BLUE,
  },
  [CARD_TYPES.PLACE_MINE]: {
    type: CARD_TYPES.PLACE_MINE,
    cost: 3,
    image: IMAGES.CARD_TYPES.IMAGES.PLACE_MINE,
    effectTypes: [CARD_EFFECT_TYPES.LIGHTWEIGHT, CARD_EFFECT_TYPES.PLACE_MINE],
    color: COLORS.CARD.BLUE,
  },
  [CARD_TYPES.PLACE_MINES_RANDOMLY]: {
    type: CARD_TYPES.PLACE_MINES_RANDOMLY,
    cost: 5,
    image: IMAGES.CARD_TYPES.IMAGES.PLACE_MINES_RANDOMLY,
    effectTypes: [CARD_EFFECT_TYPES.PLACE_MINES_RANDOMLY],
    color: COLORS.CARD.BLUE,
    stats: {
      numberOfMines: 3,
    },
  },
  [CARD_TYPES.PLACE_RANDOM_CANNONS_RANDOMLY]: {
    type: CARD_TYPES.PLACE_RANDOM_CANNONS_RANDOMLY,
    cost: 12,
    image: IMAGES.CARD_TYPES.IMAGES.PLACE_RANDOM_CANNONS_RANDOMLY,
    effectTypes: [CARD_EFFECT_TYPES.PLACE_RANDOM_CANNONS_RANDOMLY],
    color: COLORS.CARD.RED,
    stats: {
      numberOfCannons: 3,
    },
  } /*
  [CARD_TYPES.PLACE_WAREHOUSE]: {
    type: CARD_TYPES.PLACE_WAREHOUSE,
    cost: 2, 
    image: IMAGES.CARD_TYPES.IMAGES.PLACE_WAREHOUSE,
    effectTypes: [CARD_EFFECT_TYPES.PLACE_WAREHOUSE, CARD_EFFECT_TYPES.DESTROY, CARD_EFFECT_TYPES.ADD_ENERGY],
    stats: {
      energy: 3,
    },
    color: COLORS.CARD.GREEN,
  },*/,

  [CARD_TYPES.SINGLE_FIRE]: {
    type: CARD_TYPES.SINGLE_FIRE,
    cost: 1,
    image: IMAGES.CARD_TYPES.IMAGES.SINGLE_FIRE,
    effectTypes: [CARD_EFFECT_TYPES.SHOT],
    stats: {
      power: 25,
      piercing: 25,
    },
    color: COLORS.CARD.RED,
  },
  /*[CARD_TYPES.POISON_TO_ALL]: {
    type: CARD_TYPES.POISON_TO_ALL,
    energyCost: 1,
    scienceCost: 1, 
    image: IMAGES.CARD_TYPES.IMAGES.POISON_TO_ALL, 
    effectTypes: [CARD_EFFECT_TYPES.POISON_TO_ALL],
    stats: {
      poison: 2,
    },
    color: COLORS.CARD.RED,
  },*/
  [CARD_TYPES.DESTROY_BUILDING]: {
    type: CARD_TYPES.DESTROY_BUILDING,
    cost: 3,
    image: IMAGES.CARD_TYPES.IMAGES.DESTROY_BUILDING,
    effectTypes: [CARD_EFFECT_TYPES.DESTROY_OWN_BUILDING, CARD_EFFECT_TYPES.ADD_ENERGY],
    stats: {
      energy: 3,
    },
    color: COLORS.CARD.GREEN,
  },
  [CARD_TYPES.VOID]: {
    type: CARD_TYPES.VOID,
    cost: 15,
    image: IMAGES.CARD_TYPES.IMAGES.VOID,
    effectTypes: [CARD_EFFECT_TYPES.DESTROY, CARD_EFFECT_TYPES.VOID_TO_OTHERS],
    color: COLORS.CARD.GRAY,
    stats: {
      voidPower: 1,
    },
  },
  [CARD_TYPES.VOID_TO_ALL]: {
    type: CARD_TYPES.VOID_TO_ALL,
    cost: 5,
    image: IMAGES.CARD_TYPES.IMAGES.VOID_TO_ALL,
    effectTypes: [CARD_EFFECT_TYPES.LIGHTWEIGHT, CARD_EFFECT_TYPES.VOID_TO_ALL],
    color: COLORS.CARD.GRAY,
    stats: {
      voidPower: 1,
    },
  },
  [CARD_TYPES.DESTROY_FIELD]: {
    type: CARD_TYPES.DESTROY_FIELD,
    cost: 2,
    image: IMAGES.CARD_TYPES.IMAGES.DESTROY_FIELD,
    effectTypes: [CARD_EFFECT_TYPES.DRAIN, CARD_EFFECT_TYPES.DESTROY_WEAKEST_FIELDS],
    color: COLORS.CARD.GRAY,
    stats: {
      drain: 3,
      numberOfFields: 3,
    },
  },

  [CARD_TYPES.REDUCE_DRAIN_RANDOMLY]: {
    type: CARD_TYPES.REDUCE_DRAIN_RANDOMLY,
    cost: 2,
    image: IMAGES.CARD_TYPES.IMAGES.REDUCE_DRAIN_RANDOMLY,
    effectTypes: [CARD_EFFECT_TYPES.DRAIN, CARD_EFFECT_TYPES.REDUCE_DRAIN_RANDOMLY],
    color: COLORS.CARD.GREEN,
    stats: {
      drain: 6,
      drainReduction: 1,
    },
  },
  [CARD_TYPES.PLACE_DEFENDER]: {
    type: CARD_TYPES.PLACE_DEFENDER,
    cost: 3,
    image: IMAGES.CARD_TYPES.IMAGES.PLACE_DEFENDER,
    effectTypes: [CARD_EFFECT_TYPES.DRAIN, CARD_EFFECT_TYPES.PLACE_DEFENDER],
    color: COLORS.CARD.BLUE,
    stats: {
      drain: 1,
    },
  },
  [CARD_TYPES.INCREASE_ENERGY_PRODUCTION]: {
    type: CARD_TYPES.INCREASE_ENERGY_PRODUCTION,
    cost: 1,
    image: IMAGES.CARD_TYPES.IMAGES.INCREASE_ENERGY_PRODUCTION,
    effectTypes: [CARD_EFFECT_TYPES.DRAIN, CARD_EFFECT_TYPES.INCREASE_ENERGY_PRODUCTION],
    stats: {
      drain: 12,
      energyProduction: 1,
    },
    color: COLORS.CARD.GREEN,
  },
  [CARD_TYPES.SPREAD_SHOT_IMPROVING]: {
    type: CARD_TYPES.SPREAD_SHOT_IMPROVING,
    cost: 10,
    image: IMAGES.CARD_TYPES.IMAGES.SPREAD_SHOT_IMPROVING,
    effectTypes: [CARD_EFFECT_TYPES.SHOT, CARD_EFFECT_TYPES.INCREASE_OWN_SPREAD],
    stats: {
      spread: 20,
      spreadIncrease: 1,
    },
    color: COLORS.CARD.RED,
  },
  [CARD_TYPES.DRAW_CARDS]: {
    type: CARD_TYPES.DRAW_CARDS,
    cost: 5,
    image: IMAGES.CARD_TYPES.IMAGES.DRAW_CARDS,
    effectTypes: [CARD_EFFECT_TYPES.DRAW_CARDS],
    stats: {
      cards: 2,
    },
    color: COLORS.CARD.GREEN,
  },
  [CARD_TYPES.FAIR_SHOT]: {
    type: CARD_TYPES.FAIR_SHOT,
    cost: 5,
    image: IMAGES.CARD_TYPES.IMAGES.FAIR_SHOT,
    effectTypes: [CARD_EFFECT_TYPES.FAIR_SHOT],
    stats: {
      shots: 15,
    },
    color: COLORS.CARD.RED,
  },
  [CARD_TYPES.FORTIFICATE_BORDERS]: {
    type: CARD_TYPES.FORTIFICATE_BORDERS,
    cost: 10,
    image: IMAGES.CARD_TYPES.IMAGES.FORTIFICATE_BORDERS,
    effectTypes: [CARD_EFFECT_TYPES.DRAIN, CARD_EFFECT_TYPES.FORTIFICATE_BORDERS],
    stats: {
      drain: 2,
      fortifications: 2,
    },
    color: COLORS.CARD.BLUE,
  },
  [CARD_TYPES.FORTIFICATE_STRUCTURES]: {
    type: CARD_TYPES.FORTIFICATE_STRUCTURES,
    cost: 5,
    image: IMAGES.CARD_TYPES.IMAGES.FORTIFICATE_STRUCTURES,
    effectTypes: [CARD_EFFECT_TYPES.DRAIN, CARD_EFFECT_TYPES.FORTIFICATE_STRUCTURES],
    stats: {
      drain: 1,
      fortifications: 2,
    },
    color: COLORS.CARD.BLUE,
  },
  [CARD_TYPES.POWER_SHOT]: {
    type: CARD_TYPES.POWER_SHOT,
    cost: 3,
    image: IMAGES.CARD_TYPES.IMAGES.POWER_SHOT,
    effectTypes: [CARD_EFFECT_TYPES.SHOT],
    stats: {
      shots: 1,
      inaccuracy: 0,
      piercing: 2,
      power: 50,
    },
    color: COLORS.CARD.RED,
  },
  [CARD_TYPES.ENERGY_TO_BULLETS]: {
    type: CARD_TYPES.ENERGY_TO_BULLETS,
    cost: 1,
    image: IMAGES.CARD_TYPES.IMAGES.ENERGY_TO_BULLETS,
    effectTypes: [CARD_EFFECT_TYPES.ENERGY_TO_BULLETS],
    stats: {
      spread: 25,
    },
    color: COLORS.CARD.RED,
  },

  [CARD_TYPES.PLACE_SHORT_RANGE_CANNON]: {
    type: CARD_TYPES.PLACE_SHORT_RANGE_CANNON,
    cost: 2,
    image: IMAGES.CARD_TYPES.IMAGES.PLACE_SHORT_RANGE_CANNON,
    effectTypes: [CARD_EFFECT_TYPES.PLACE_SHORT_RANGE_CANNON],
    color: COLORS.CARD.BLUE,
  },
  [CARD_TYPES.FORTIFICATE_HEADQUARTER_AND_ADJACENTS]: {
    type: CARD_TYPES.FORTIFICATE_HEADQUARTER_AND_ADJACENTS,
    cost: 3,
    image: IMAGES.CARD_TYPES.IMAGES.FORTIFICATE_HEADQUARTER_AND_ADJACENTS,
    effectTypes: [CARD_EFFECT_TYPES.DESTROY, CARD_EFFECT_TYPES.FORTIFICATE_HEADQUARTER_AND_ADJACENTS],
    stats: {
      fortifications: 8,
    },
    color: COLORS.CARD.BLUE,
  },
  [CARD_TYPES.INCREASE_CARD_LIMIT]: {
    type: CARD_TYPES.INCREASE_CARD_LIMIT,
    cost: 12,
    image: IMAGES.CARD_TYPES.IMAGES.INCREASE_CARD_LIMIT,
    effectTypes: [CARD_EFFECT_TYPES.DESTROY, CARD_EFFECT_TYPES.INCREASE_CARD_LIMIT],
    color: COLORS.CARD.GREEN,
  },
  [CARD_TYPES.SNIPER_SHOT_IMPROVING]: {
    type: CARD_TYPES.SNIPER_SHOT_IMPROVING,
    cost: 10,
    image: IMAGES.CARD_TYPES.IMAGES.SNIPER_SHOT_IMPROVING,
    effectTypes: [CARD_EFFECT_TYPES.SHOT, CARD_EFFECT_TYPES.INCREASE_OWN_SHOTS],
    stats: {
      shots: 5,
      inaccuracy: 0,
      shotsIncrease: 1,
    },
    color: COLORS.CARD.RED,
  },
  [CARD_TYPES.TRASH_AND_COPY_CARD]: {
    type: CARD_TYPES.TRASH_AND_COPY_CARD,
    cost: 8,
    image: IMAGES.CARD_TYPES.IMAGES.TRASH_AND_COPY_CARD,
    effectTypes: [CARD_EFFECT_TYPES.TRASH_AND_COPY_CARD],
    stats: {
      copies: 3,
    },
    color: COLORS.CARD.GREEN,
  },
  [CARD_TYPES.ACTIVATE_CANNONS]: {
    type: CARD_TYPES.ACTIVATE_CANNONS,
    cost: 10,
    image: IMAGES.CARD_TYPES.IMAGES.ACTIVATE_CANNONS,
    effectTypes: [CARD_EFFECT_TYPES.ACTIVATE_CANNONS],
    stats: {
      times: 2,
    },
    color: COLORS.CARD.RED,
  },
  [CARD_TYPES.REPLACE_HAND]: {
    type: CARD_TYPES.REPLACE_HAND,
    cost: 6,
    image: IMAGES.CARD_TYPES.IMAGES.REPLACE_HAND,
    effectTypes: [CARD_EFFECT_TYPES.REPLACE_HAND],
    color: COLORS.CARD.GREEN,
  },
};
