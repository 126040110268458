import { TOOLTIP } from 'common/consts/tooltip.js';
import { CARD_EFFECT_TYPES } from 'common/consts/types/card-effect.js';

import { BaseCardEffect } from '../base.js';

export class PoisonToAllCardEffect extends BaseCardEffect {
  constructor(params) {
    super(params);

    this.type = CARD_EFFECT_TYPES.POISON_TO_ALL;
  }

  getTooltipTypes() {
    return [TOOLTIP.TYPES.POISON];
  }

  execute({ card }) {
    const { poison } = card.getStats();

    this.game.kingdomsController.eachKingdom((kingdom) => {
      kingdom.addEffect({ type: CARD_EFFECT_TYPES.POISON, power: poison });
    });
  }
}
