import { CARD_TYPES } from 'common/consts/types/card.js';

import { Kingdom } from '../kingdom.js';

export class HumanKingdom extends Kingdom {
  startingCards() {
    return [
      this.createCard({ type: CARD_TYPES.SPREAD_SHOT }),
      this.createCard({ type: CARD_TYPES.SPREAD_SHOT }),

      this.createCard({ type: CARD_TYPES.SNIPER_FIRE }),
      this.createCard({ type: CARD_TYPES.SNIPER_FIRE }),

      this.createCard({ type: CARD_TYPES.FORTIFICATE_OWN_FIELD }),
      this.createCard({ type: CARD_TYPES.FORTIFICATE_OWN_FIELD }),

      this.createCard({ type: CARD_TYPES.RANDOM_FORTIFICATIONS }),
      this.createCard({ type: CARD_TYPES.RANDOM_FORTIFICATIONS }),

      this.createCard({ type: CARD_TYPES.SPARK }),
      this.createCard({ type: CARD_TYPES.SPARK }),
      this.createCard({ type: CARD_TYPES.SPARK }),
      this.createCard({ type: CARD_TYPES.SPARK }),

      this.createCard({ type: CARD_TYPES.JUNK }),
      this.createCard({ type: CARD_TYPES.JUNK }),
      this.createCard({ type: CARD_TYPES.JUNK }),
      this.createCard({ type: CARD_TYPES.JUNK }),
    ];
  }

  setup() {
    super.setup();

    this.addStartingFortification();
  }

  addStartingFortification() {
    if (!this.difficulty) return;

    let fortification = 0;

    if (this.difficulty == 1) fortification += 100;
    if (this.difficulty == 2) fortification += 30;

    this.addMainFortification(fortification);
  }
}
