import { CARD_EFFECT_TYPES } from 'common/consts/types/card-effect.js';

import { BaseCardEffect } from '../base.js';

export class LimitedUsageCardEffect extends BaseCardEffect {
  constructor(params) {
    super(params);

    this.type = CARD_EFFECT_TYPES.LIMITED_USAGE;
  }

  afterExecute({ card }) {
    const { usage } = card.getStats();

    if (usage <= 1) {
      card.orderTrashing();
    } else {
      card.increaseStat('usage', -1);
    }
  }
}
