import { TOOLTIP } from 'common/consts/tooltip.js';
import { CARD_EFFECT_TYPES } from 'common/consts/types/card-effect.js';

import { BaseCardEffect } from '../base.js';

export class FortificateOwnFieldCardEffect extends BaseCardEffect {
  constructor(params) {
    super(params);

    this.type = CARD_EFFECT_TYPES.FORTIFICATE_OWN_FIELD;
  }

  getTooltipTypes() {
    return [TOOLTIP.TYPES.FORTIFICATIONS];
  }

  execute({ card, targets }) {
    const { fortifications } = card.getStats();

    const field = this.game.entitiesController.findById(targets.fieldId);
    field.changeFortifications(fortifications);
  }

  pickTarget({ kingdom }) {
    return kingdom.orderPickingOwnFieldToFortificate();
  }

  canBePlayedOnTarget({ kingdom, targets }) {
    const field = this.game.entitiesController.findById(targets.fieldId);

    if (!field.ownedBy(kingdom)) return this.getCant('This field is not yours!');

    return this.getCan();
  }
}
