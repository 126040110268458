import { ActivateCannonsCardEffect } from 'common/card-effects/activate-cannons.js';
import { AddEnergyCardEffect } from 'common/card-effects/add-energy.js';
import { DestroyCardEffect } from 'common/card-effects/cards/destroy.js';
import { DrawCardsCardEffect } from 'common/card-effects/cards/draw-cards.js';
import { FragileCardEffect } from 'common/card-effects/cards/fragile.js';
import { IncreaseCardLimitCardEffect } from 'common/card-effects/cards/increase-card-limit.js';
import { IncreaseOwnShotsCardEffect } from 'common/card-effects/cards/increase-own-shots.js';
import { IncreaseOwnSpreadCardEffect } from 'common/card-effects/cards/increase-own-spread.js';
import { LightweightCardEffect } from 'common/card-effects/cards/lightweight.js';
import { LimitedUsageCardEffect } from 'common/card-effects/cards/limited-usage.js';
import { ReduceDrainRandomlyCardEffect } from 'common/card-effects/cards/reduce-drain-randomly.js';
import { RefillCardEffect } from 'common/card-effects/cards/refill.js';
import { ReplaceHandCardEffect } from 'common/card-effects/cards/replace-hand.js';
import { TrashAndCopyCardCardEffect } from 'common/card-effects/cards/trash-and-copy-card.js';
import { TrashCardCardEffect } from 'common/card-effects/cards/trash-card.js';
import { UnplayableCardEffect } from 'common/card-effects/cards/unplayable.js';
import { DestroyOwnBuildingCardEffect } from 'common/card-effects/destroy-own-building.js';
import { DrainCardEffect } from 'common/card-effects/drain.js';
import { FortificateAroundHeadquarterCardEffect } from 'common/card-effects/fortificate/fortificate-around-headquarter.js';
import { FortificateBordersCardEffect } from 'common/card-effects/fortificate/fortificate-borders.js';
import { FortificateHeadquarterAndAdjacentsCardEffect } from 'common/card-effects/fortificate/fortificate-headquarter-and-adjacents.js';
import { FortificateOwnFieldCardEffect } from 'common/card-effects/fortificate/fortificate-own-field.js';
import { FortificateStructuresCardEffect } from 'common/card-effects/fortificate/fortificate-structures.js';
import { RandomFortificationsCardEffect } from 'common/card-effects/fortificate/random-fortifications.js';
import { ImproveHeadquarterCardEffect } from 'common/card-effects/improve-headquarter.js';
import { IncreaseEnergyProductionCardEffect } from 'common/card-effects/increase-energy-production.js';
import { DestroyWeakestFieldsCardEffect } from 'common/card-effects/kingdom-effects/destroy-weakest-fields.js';
import { PoisonToAllCardEffect } from 'common/card-effects/kingdom-effects/poison-to-all.js';
import { VoidToAllCardEffect } from 'common/card-effects/kingdom-effects/void-to-all.js';
import { VoidToOthersCardEffect } from 'common/card-effects/kingdom-effects/void-to-others.js';
import { FortificateAndPlaceReinforcementCardEffect } from 'common/card-effects/place/fortificate-and-place-reinforcement.js';
import { PlaceBasicCannonCardEffect } from 'common/card-effects/place/place-basic-cannon.js';
import { PlaceDefenderCardEffect } from 'common/card-effects/place/place-defender.js';
import { PlaceForgeCardEffect } from 'common/card-effects/place/place-forge.js';
import { PlaceMineCardEffect } from 'common/card-effects/place/place-mine.js';
import { PlaceMinesRandomlyCardEffect } from 'common/card-effects/place/place-mines-randomly.js';
import { PlacePowerStationCardEffect } from 'common/card-effects/place/place-power-station.js';
import { PlaceRandomCannonsRandomlyCardEffect } from 'common/card-effects/place/place-random-cannons-randomly.js';
import { PlaceShortRangeCannonCardEffect } from 'common/card-effects/place/place-short-range-cannon.js';
import { PlaceSniperCannonCardEffect } from 'common/card-effects/place/place-sniper-cannon.js';
import { PlaceWarehouseCardEffect } from 'common/card-effects/place/place-warehouse.js';
import { EnergyToBulletsCardEffect } from 'common/card-effects/shots/energy-to-bullets.js';
import { FairShotCardEffect } from 'common/card-effects/shots/fair-shot.js';
import { MultishotCardEffect } from 'common/card-effects/shots/multishot.js';
import { ShotCardEffect } from 'common/card-effects/shots/shot.js';

export class CardEffectsManager {
  constructor(game) {
    this.game = game;
    this.effects = {};

    this.registerEffects();
  }

  registerEffect(effect) {
    this.effects[effect.type] = effect;
  }

  registerEffects() {
    this.registerEffect(new AddEnergyCardEffect({ game: this.game }));
    this.registerEffect(new UnplayableCardEffect({ game: this.game }));
    this.registerEffect(new DrainCardEffect({ game: this.game }));
    this.registerEffect(new MultishotCardEffect({ game: this.game }));
    this.registerEffect(new ShotCardEffect({ game: this.game }));
    this.registerEffect(new EnergyToBulletsCardEffect({ game: this.game }));
    this.registerEffect(new FortificateHeadquarterAndAdjacentsCardEffect({ game: this.game }));
    this.registerEffect(new FortificateAroundHeadquarterCardEffect({ game: this.game }));
    this.registerEffect(new PlaceWarehouseCardEffect({ game: this.game }));
    this.registerEffect(new PlacePowerStationCardEffect({ game: this.game }));
    this.registerEffect(new PlaceForgeCardEffect({ game: this.game }));
    this.registerEffect(new PlaceShortRangeCannonCardEffect({ game: this.game }));
    this.registerEffect(new PlaceDefenderCardEffect({ game: this.game }));
    this.registerEffect(new PlaceBasicCannonCardEffect({ game: this.game }));
    this.registerEffect(new PlaceSniperCannonCardEffect({ game: this.game }));
    this.registerEffect(new PlaceMineCardEffect({ game: this.game }));
    this.registerEffect(new PlaceRandomCannonsRandomlyCardEffect({ game: this.game }));
    this.registerEffect(new FortificateAndPlaceReinforcementCardEffect({ game: this.game }));
    this.registerEffect(new DestroyOwnBuildingCardEffect({ game: this.game }));
    this.registerEffect(new FortificateOwnFieldCardEffect({ game: this.game }));
    this.registerEffect(new FortificateBordersCardEffect({ game: this.game }));
    this.registerEffect(new ReduceDrainRandomlyCardEffect({ game: this.game }));
    this.registerEffect(new TrashAndCopyCardCardEffect({ game: this.game }));
    this.registerEffect(new TrashCardCardEffect({ game: this.game }));
    this.registerEffect(new IncreaseOwnSpreadCardEffect({ game: this.game }));
    this.registerEffect(new IncreaseOwnShotsCardEffect({ game: this.game }));
    this.registerEffect(new FairShotCardEffect({ game: this.game }));
    this.registerEffect(new PlaceMinesRandomlyCardEffect({ game: this.game }));
    this.registerEffect(new ImproveHeadquarterCardEffect({ game: this.game }));
    this.registerEffect(new ActivateCannonsCardEffect({ game: this.game }));
    this.registerEffect(new DrawCardsCardEffect({ game: this.game }));
    this.registerEffect(new IncreaseEnergyProductionCardEffect({ game: this.game }));
    this.registerEffect(new PoisonToAllCardEffect({ game: this.game }));
    this.registerEffect(new VoidToAllCardEffect({ game: this.game }));
    this.registerEffect(new VoidToOthersCardEffect({ game: this.game }));
    this.registerEffect(new RandomFortificationsCardEffect({ game: this.game }));
    this.registerEffect(new FortificateStructuresCardEffect({ game: this.game }));
    this.registerEffect(new IncreaseCardLimitCardEffect({ game: this.game }));
    this.registerEffect(new DestroyWeakestFieldsCardEffect({ game: this.game }));
    this.registerEffect(new DestroyCardEffect({ game: this.game }));
    this.registerEffect(new FragileCardEffect({ game: this.game }));
    this.registerEffect(new LimitedUsageCardEffect({ game: this.game }));
    this.registerEffect(new RefillCardEffect({ game: this.game }));
    this.registerEffect(new LightweightCardEffect({ game: this.game }));
    this.registerEffect(new ReplaceHandCardEffect({ game: this.game }));
  }

  getDescriptionForEffectType(effectType, stats) {
    const effect = this.getEffect(effectType);
    if (!effect) return 'MISSING EFFECT';

    return effect.getDescription(stats);
  }

  getTooltipTypes(effectTypes, stats) {
    const tooltips = [];

    effectTypes.forEach((effectType) => {
      const effect = this.getEffect(effectType);
      tooltips.push(effect.getTooltipTypes(stats));
    });

    return [...new Set(tooltips.flat())];
  }

  getEffect(effectType) {
    return this.effects[effectType];
  }

  canBePlayed = (card, kingdom) => {
    const cardData = card.getData();
    const { effectTypes } = cardData;

    for (let i = 0; i < effectTypes.length; i++) {
      const effectType = effectTypes[i];

      const effect = this.getEffect(effectType);

      if (!effect) console.log('MISSING EFFECT FOR CARD:', card);

      const result = effect.canBePlayed({ kingdom, card });
      if (!result.can) return result;
    }

    return this.confirmationReturn();
  };

  errorReturn(message, error) {
    return { can: false, error, message: message };
  }

  confirmationReturn() {
    return { can: true };
  }

  canBePlayedOnTarget(cardId, targets, kingdom) {
    const card = kingdom.searchForPlayableCard(cardId);
    if (!card) return this.errorReturn('Card not in hand!');

    const cardData = card.getData();
    const { effectTypes } = cardData;

    for (let i = 0; i < effectTypes.length; i++) {
      const effectType = effectTypes[i];

      const effect = this.getEffect(effectType);

      const result = effect.canBePlayedOnTarget({ kingdom, card, targets });
      if (!result.can) return result;
    }

    return this.confirmationReturn();
  }

  pickTargetsForCard = async (card, kingdom) => {
    const cardData = card.getData();
    const { effectTypes } = cardData;

    let targets = {};

    for (let i = 0; i < effectTypes.length; i++) {
      const effectType = effectTypes[i];
      const effect = this.getEffect(effectType);

      const result = effect.pickTarget({ kingdom, card });
      targets = { ...targets, ...result };
    }

    return targets;
  };

  playCard = (cardId, targets, kingdom) => {
    const card = kingdom.searchForPlayableCard(cardId);

    if (!card) return false;

    const cardData = card.getData();
    const { effectTypes } = cardData;

    for (let i = 0; i < effectTypes.length; i++) {
      const effectType = effectTypes[i];

      const effect = this.getEffect(effectType);

      effect.execute({ kingdom, card, targets });
    }

    for (let i = 0; i < effectTypes.length; i++) {
      const effectType = effectTypes[i];

      const effect = this.getEffect(effectType);

      effect.afterExecute({ kingdom, targets, card });
    }
  };

  cancelCard(kingdom, card) {
    const cardData = card.getData();
    const { energyCost } = cardData;

    kingdom.active.removeCard(card);
    kingdom.hand.addCard(card);

    if (energyCost) {
      kingdom.changeEnergy(energyCost);
    }
  }
}
