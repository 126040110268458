import { uniqueString } from 'common/helpers/data.js';

export const ERROR_TYPES = {
  NOT_ENOUGH_ENERGY: uniqueString(),
  NO_EMPTY_FIELDS: uniqueString(),
  NO_HEADQUARTER: uniqueString(),
  NO_POSSIBLE_CARD_TARGET: uniqueString(),
  UNPLAYABLE: uniqueString(),
  NOT_YOUR_FIELD: uniqueString(),
  FIELD_NOT_EMPTY: uniqueString(),
};
