import { CARD_EFFECT_TYPES } from 'common/consts/types/card-effect.js';

import { BaseCardEffect } from './base.js';

export class DestroyOwnBuildingCardEffect extends BaseCardEffect {
  constructor(params) {
    super(params);

    this.type = CARD_EFFECT_TYPES.DESTROY_OWN_BUILDING;
  }

  execute({ targets }) {
    const field = this.game.entitiesController.findById(targets.fieldId);
    field.destroyBuilding();
  }

  canBePlayed({ kingdom }) {
    const ownBuildings = this.game.mapController
      .getAllFields()
      .filter((field) => field.ownedBy(kingdom) && field.structure && field.structure.demolitionable);

    if (ownBuildings.length === 0) {
      return this.getCant('No buldings to destroy!');
    }

    return this.getCan();
  }

  canBePlayedOnTarget({ targets }) {
    const field = this.game.entitiesController.findById(targets.fieldId);

    if (!field.structure) return this.getCant('No building on the field!');
    if (!field.structure.demolitionable) return this.getCant("Can't be destroyed!");

    return this.getCan();
  }

  pickTarget({ kingdom }) {
    return kingdom.orderPickingOwnBuildingToDestroy();
  }
}
