import { Point } from '../../data-types/point.js';

import { CARD_APEARING_AREA_X, CARD_APEARING_AREA_Y } from 'client/consts/layout.js';

import { COPING_CARD_DELAY } from 'common/consts/gameplay.js';
import { TOOLTIP } from 'common/consts/tooltip.js';
import { CARD_EFFECT_TYPES } from 'common/consts/types/card-effect.js';

import { addTemporaryToEffects } from 'common/helpers/effects-helpers.js';

import { BaseCardEffect } from '../base.js';

export class TrashAndCopyCardCardEffect extends BaseCardEffect {
  constructor(params) {
    super(params);

    this.type = CARD_EFFECT_TYPES.TRASH_AND_COPY_CARD;
  }

  getTooltipTypes() {
    return [TOOLTIP.TYPES.PERSISTENT];
  }

  execute = async ({ kingdom, card, targets }) => {
    const { copies } = card.getStats();

    const targetCard = this.game.entitiesController.findById(targets.cardId);

    const newCardData = { effectTypes: [], ...targetCard.getData() };
    addTemporaryToEffects(newCardData.effectTypes);

    for (let i = 0; i < copies; i++) {
      const newCard = this.game.cardsCreator.createCustomCard(newCardData, kingdom, {
        position: new Point(window.innerWidth - CARD_APEARING_AREA_X, window.innerHeight - CARD_APEARING_AREA_Y),
      });
      kingdom.putInDiscard(newCard);

      newCard.makeInvisible(i * COPING_CARD_DELAY);
      newCard.showFront();
    }

    targetCard.orderTrashing();
  };

  pickTarget({ kingdom }) {
    return kingdom.orderPickingHandCardToTrashAndCopy();
  }

  canBePlayed({ kingdom, card }) {
    const cards = kingdom.getHand();

    const persistentCards = cards.filter((filteredCard) => filteredCard.isPersistent() && filteredCard.getId() !== card.getId());

    if (persistentCards.length === 0) {
      return this.getCant('No cards to pick!');
    }

    return this.getCan();
  }

  canBePlayedOnTarget({ targets }) {
    const targetCard = this.game.entitiesController.findById(targets.cardId);

    if (!targetCard.isPersistent()) return this.getCant('This cart is not persistent.');

    return this.getCan();
  }
}
