import * as PIXI from 'pixi.js';

import { SOUNDS } from 'client/consts/sounds.js';
import { Z_INDEX } from 'client/consts/z-index.js';
import { withLeftClick } from 'client/utils/with-left-click.js';

import { IMAGES } from 'common/consts/types/images.js';

import { perFrameTimer } from 'common/helpers/converters.js';

import { InterfaceBase } from '../interface-base.js';

const SOUND_DELAY = perFrameTimer(0.05);

export class Target extends InterfaceBase {
  constructor(params) {
    super(params);

    this.kingdom = params.kingdom;
    this.parent = params.parent;

    this.moveable = false;
    this.lockingPosition = null;

    this.interactive = false;
    this.interactiveBlockades = 1;

    this.soundTimer = 0;

    this.createSprite();
    this.updatePosition();
  }

  makeMoveable() {
    this.moveable = true;
    this.sprites.overlay.visible = true;
    this.sprites.shadowTarget.visible = true;
  }

  makeInmoveable() {
    this.moveable = false;
    this.sprites.overlay.visible = false;
    this.sprites.shadowTarget.visible = false;

    this.lockingPosition = null;
    this.updatePosition();
  }

  blockInteractions() {
    this.sprites.clickable.eventMode = 'none';
  }

  unblockInteractions() {
    this.sprites.clickable.eventMode = 'static';
  }

  createSprite() {
    this.sprites.clickable = new PIXI.Sprite();

    this.sprites.clickable.x = 0;
    this.sprites.clickable.y = 0;

    this.sprites.clickable.hitArea = new PIXI.Circle(0, 0, 65);
    this.sprites.clickable.on('pointerdown', withLeftClick(this.onTargetClick));
    this.sprites.clickable.on('rightdown', this.mouseRightDown);
    this.sprites.clickable.eventMode = 'static';
    this.sprites.clickable.cursor = 'pointer';

    this.registerMapInterfaceSprite(this.sprites.clickable);

    this.sprites.shadowTarget = this.game.texturesManager.createStandardSprite(IMAGES.ENTITIES.TARGET);
    this.sprites.shadowTarget.x = 0;
    this.sprites.shadowTarget.y = 0;

    this.sprites.shadowTarget.tint = this.kingdom.getColor();
    this.sprites.shadowTarget.alpha = 0.5;
    this.sprites.shadowTarget.zIndex = Z_INDEX.TARGET;

    this.registerMapSprite(this.sprites.shadowTarget);

    this.sprites.target = this.game.texturesManager.createStandardSprite(IMAGES.ENTITIES.TARGET);
    this.sprites.target.x = 0;
    this.sprites.target.y = 0;

    this.sprites.target.tint = this.kingdom.getColor();
    this.sprites.target.zIndex = Z_INDEX.TARGET;

    this.registerMapSprite(this.sprites.target);

    this.sprites.overlay = new PIXI.Container();
    this.sprites.overlay.hitArea = new PIXI.Rectangle(0, 0, window.innerWidth, window.innerHeight);
    this.sprites.overlay.eventMode = 'static';
    this.sprites.overlay.zIndex = Z_INDEX.SELECTION_OVERLAY;
    this.sprites.overlay.visible = false;
    this.sprites.overlay.on('pointerdown', withLeftClick(this.onOverlayClick));
    this.sprites.overlay.on('rightdown', this.mouseRightDown);

    this.registerSprite(this.sprites.overlay);
  }

  updatePosition() {
    const position = this.kingdom.getTarget().clone();

    this.sprites.target.x = position.x;
    this.sprites.target.y = position.y;

    this.sprites.clickable.x = position.x;
    this.sprites.clickable.y = position.y;

    this.sprites.shadowTarget.x = position.x;
    this.sprites.shadowTarget.y = position.y;
  }

  onTargetClick = () => {
    this.parent.onTargetClick();
  };

  onOverlayClick = () => {
    if (!this.lockingPosition) return;

    this.parent.onTargetOverlayClick({ point: this.lockingPosition.clone() });
  };

  mouseRightDown = () => {
    this.game.eventsController.runEvent('redirectedMouseRightDown');
  };

  destroy() {
    super.destroy();

    this.removeMapInterfaceSprite(this.sprites.clickable);
    this.removeMapSprite(this.sprites.target);
    this.removeSprite(this.sprites.overlay);
  }

  activate(delta) {
    this.moveToMouseHex();
    this.changeCursorForOverlay();
    this.calculateSoundTimer(delta);
  }

  calculateSoundTimer(delta) {
    if (this.soundTimer <= 0) return;

    this.soundTimer -= delta;

    if (this.soundTimer > 0) return;

    this.playSound();
  }

  playSound() {
    this.game.soundsController.playSound(SOUNDS.SELECTING_FIELD);
  }

  playDelayedSound() {
    this.soundTimer = SOUND_DELAY;
  }

  moveToMouseHex() {
    if (!this.moveable) return;

    const mouseMapPosition = this.game.inputsController.getMouseMapPosition().clone();
    const hexPosition = mouseMapPosition.toHex();

    const field = this.game.mapController.getFieldByHex(hexPosition);

    if (!field) {
      this.lockingPosition = false;
      return;
    }

    const finalPosition = hexPosition.toPoint();

    if (finalPosition.sameAs(this.lockingPosition)) return false;

    this.lockingPosition = finalPosition;

    this.sprites.target.x = finalPosition.x;
    this.sprites.target.y = finalPosition.y;

    this.sprites.clickable.x = finalPosition.x;
    this.sprites.clickable.y = finalPosition.y;

    this.playDelayedSound();
  }

  changeCursorForOverlay() {
    if (!this.moveable) return false;

    const mouseMapPosition = this.game.inputsController.getMouseMapPosition().clone();
    const hexPosition = mouseMapPosition.toHex();

    const field = this.game.mapController.getFieldByHex(hexPosition);

    this.sprites.overlay.cursor = field ? 'pointer' : 'default';
  }

  afterResize = () => {
    this.sprites.overlay.hitArea = new PIXI.Rectangle(0, 0, window.innerWidth, window.innerHeight);
  };
}
