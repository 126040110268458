import { MarketBoard } from './market-board.js';

export class RandomMarketBoard extends MarketBoard {
  constructor(params) {
    super(params);

    this.initialize();
  }

  setCardsTypes() {
    this.cardsTypes = this.game.marketController.getRandomChoices();
  }
}
