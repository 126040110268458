import { MarketBoard } from './market-board.js';

export class FixedMarketBoard extends MarketBoard {
  constructor(params) {
    super(params);

    this.initialize();
  }

  setCardsTypes() {
    this.cardsTypes = this.game.marketController.getFixedChoices();
  }
}
