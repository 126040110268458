import { Application } from 'pixi.js';

import { ClientConnectionController } from 'client/controllers/client-connection.js';
import { HostConnectionController } from 'client/controllers/host-connection.js';
import { InputsController } from 'client/controllers/inputs.js';
import { InterfaceController } from 'client/controllers/interface.js';
import { SettingsController } from 'client/controllers/settings.js';
import { SoundsController } from 'client/controllers/sounds.js';
import { TextsController } from 'client/controllers/texts.js';
import { DevTools } from 'client/services/dev-tools.js';
import { TexturesManager } from 'client/services/textures-manager.js';

import { FRAMES_DELAY } from 'common/consts/game.js';
import { COUNTDOWN_TO_START, DEFAULT_CYCLE_TIME, MAP_STANDARD_SIZE } from 'common/consts/gameplay.js';
import { PAUSED, PLAYING, PREPARING, WAITING } from 'common/consts/statuses.js';

import { perFrameSpeed, perFrameTimer } from 'common/helpers/converters.js';
import { generateId } from 'common/helpers/data.js';
import { sleep } from 'common/helpers/sleep.js';

import { EntitiesController } from 'common/controllers/entities.js';
import { EventsController } from 'common/controllers/events.js';
import { KingdomsController } from 'common/controllers/kingdoms.js';
import { MapController } from 'common/controllers/map.js';
import { MarketController } from 'common/controllers/market.js';
import { Timer } from 'common/data-types/timer.js';
import { CardEffectsManager } from 'common/services/card-effects-manager.js';
import { CardsCreator } from 'common/services/cards-creator.js';
import { EntitiesCreator } from 'common/services/entities-creator.js';
import { StructuresCreator } from 'common/services/structures-creator.js';

import { CHEATS } from './cheats.js';

export class Game {
  constructor(params = {}) {
    this.pixiApp = null;

    this.status = PREPARING;
    this.countdownTimer = null;

    this.mapSize = MAP_STANDARD_SIZE;

    this.toCycleEnd = null;
    this.cycleTime = params.cycleTime || DEFAULT_CYCLE_TIME;

    this.playerId = params.playerId;
    this.playerToken = params.playerToken;
    this.playerNick = params.playerNick;
    this.serverUrl = params.serverUrl;

    this.isMultiplayer = params.isMultiplayer;
    this.isHost = params.isHost;
    this.preparation = params.preparation;

    this.onReadyCallback = params.onReady;
    this.onEndCallback = params.onEnd;

    this.difficulty = params.difficulty || 2;
  }

  shouldConfirmWithHost() {
    return this.isMultiplayer && !this.isHost;
  }

  shouldSendEvent() {
    return this.isMultiplayer && this.isHost;
  }

  initialize = async () => {
    if (this.isMultiplayer) {
      await this.initializeMultiplayer();
    } else {
      await this.initializeSingleplayer();
    }

    this.startLooping();
  };

  initializeSingleplayer = async () => {
    this.resetCycleTimer();

    this.interfacePart = true;
    this.advancedLogicPart = true;

    await this.createPixiApp();

    this.createControllers();
    this.initializeServices();
    await this.setupControllers();

    this.prepareMap();
    this.kingdomsController.setupSingleplayer({ difficulty: this.difficulty });

    this.interfaceController.focusOnMainHeadquarter();

    this.interfaceController.createInterface();
    this.interfaceController.createMainKingdomInterface();

    this.devTools.setup();

    this.onPrepared();
    this.onStart();
  };

  initializeMultiplayer = async () => {
    this.interfacePart = true;
    this.multiplayerPart = true;

    await this.createPixiApp();

    this.createControllers();
    this.initializeServices();
    await this.setupControllers();

    if (this.isHost) {
      this.prepareAsHost();
    } else {
      this.connectToHost();
    }

    this.interfaceController.createInterface();
  };

  prepareAsHost() {
    this.advancedLogicPart = true;

    this.connectionController.prepare(this.playerId, this.preparation);

    this.resetCycleTimer();
    this.prepareMap();
    this.kingdomsController.setupHostMultiplayer(this.preparation, this.playerId);

    this.interfaceController.focusOnMainHeadquarter();

    this.interfaceController.createMainKingdomInterface();

    this.startCountdown();
    this.onPrepared();
  }

  connectToHost() {
    this.connectionController.createPeer();
    this.connectionController.connectToHost(this.preparation.hostPeerId);
  }

  createPixiApp = async () => {
    this.pixiApp = new Application();
    await this.pixiApp.init({ resizeTo: window, antialias: true, renderableGCActive: false });

    this.renderer = this.pixiApp.renderer;

    document.getElementById('game-container').appendChild(this.pixiApp.canvas);
  };

  startLooping = () => {
    this.cycleTimer = new Timer();

    this.loopTimeout = setTimeout(this.loop, FRAMES_DELAY);
  };

  loop = () => {
    let delta = this.cycleTimer.passed() / FRAMES_DELAY;
    this.cycleTimer.reset();

    if (!this.multiplayerPart) {
      if (delta > 4) delta = 4;
    }

    try {
      this.activate(delta);
    } catch (error) {
      console.log(error);
      return this.destroy({ title: 'Code exception', message: 'Something went wrong, sorry.' });
    }

    const delay = Math.max(FRAMES_DELAY - this.cycleTimer.passed(), 1);

    this.loopTimeout = setTimeout(this.loop, delay);
  };

  createControllers() {
    this.settingsController = new SettingsController(this);

    this.eventsController = new EventsController(this);
    this.entitiesController = new EntitiesController(this);
    this.mapController = new MapController(this);
    this.kingdomsController = new KingdomsController(this);

    this.inputsController = new InputsController(this);
    this.interfaceController = new InterfaceController(this);
    this.textsController = new TextsController(this);

    if (this.isMultiplayer) {
      if (this.isHost) {
        this.connectionController = new HostConnectionController(this);
      } else {
        this.connectionController = new ClientConnectionController(this);
      }
    }

    this.marketController = new MarketController(this);
    this.soundsController = new SoundsController(this);
  }

  initializeServices() {
    this.entitiesCreator = new EntitiesCreator(this);
    this.structuresCreator = new StructuresCreator(this);
    this.cardsCreator = new CardsCreator(this);
    this.cardEffectsManager = new CardEffectsManager(this);

    this.texturesManager = new TexturesManager(this);

    this.devTools = new DevTools(this);
  }

  setupControllers = async () => {
    this.interfaceController.configure();
    this.inputsController.startHandling();
    this.marketController.prepareChoices();
    await this.texturesManager.loadAll();
  };

  activate = (delta) => {
    const paused = this.isPaused();

    this.entitiesController.activate(delta, paused);
    this.mapController.activate(delta, paused);

    this.interfaceController.activate(delta, paused);
    this.inputsController.activate(delta, paused);

    if (this.isMultiplayer) this.connectionController.activate(delta, paused);

    this.devTools.activate(delta);

    if (!paused) this.calculateCycle(perFrameSpeed(delta));

    if (this.isCountdown()) {
      this.calculateCountdown(delta);
    }
  };

  calculateCountdown(delta) {
    if (this.countdownTimer < 0) return;

    this.countdownTimer -= delta;

    if (this.countdownTimer > 0) return;

    this.onStart();
  }

  isPlaying() {
    return this.status === PLAYING;
  }

  isPaused() {
    return this.status !== PLAYING;
  }

  joinCurrentMatch() {
    this.connectionController.joinServerAndMatch();
  }

  prepareMap() {
    this.mapController.prepareMap({ size: this.mapSize });
  }

  drawCardsFromSD(socketData) {
    const mainKingdom = this.kingdomsController.getMainKingdom();

    socketData.forEach((cardData) => {
      mainKingdom.drawById(cardData.id);
    });
  }

  discardCardsFromSD(socketData) {
    const mainKingdom = this.kingdomsController.getMainKingdom();

    socketData.forEach((cardData) => {
      mainKingdom.discardById(cardData.id);
    });
  }

  trashCardsFromSD(socketData) {
    const mainKingdom = this.kingdomsController.getMainKingdom();

    socketData.forEach((cardData) => {
      mainKingdom.trashById(cardData.id);
    });
  }

  updateCardsFromSD(socketData) {
    socketData.forEach((cardData) => {
      const { energyCost, id, stats } = cardData;
      const card = this.entitiesController.findById(id);

      if (!card) return;

      if (typeof energyCost !== 'undefined') card.overrideEnergyCost(energyCost);
      card.overrideStats(stats);
    });
  }

  returnCardsFromSD(socketData) {
    const mainKingdom = this.kingdomsController.getMainKingdom();

    socketData.forEach((cardData) => {
      mainKingdom.returnById(cardData.id);
    });
  }

  triggerShuffilingFromSD() {
    const mainKingdom = this.kingdomsController.getMainKingdom();
    mainKingdom.startShuffiling();
  }

  updateKingdomsFromSD(socketData) {
    socketData.forEach((changeData) => {
      const kingdom = this.kingdomsController.getKingdomById(changeData.id);

      if (!kingdom) return;

      kingdom.update(changeData);
    });
  }

  updateMainKingdomFromSD(socketData) {
    const mainKingdom = this.kingdomsController.getMainKingdom();
    mainKingdom.update(socketData);
  }

  addEffectsFromSD(socketData) {
    const mainKingdom = this.kingdomsController.getMainKingdom();

    socketData.forEach((effectData) => {
      mainKingdom.addEffect(effectData);
    });
  }

  updateEffectsFromSD(socketData) {
    const mainKingdom = this.kingdomsController.getMainKingdom();

    socketData.forEach((effectData) => {
      mainKingdom.updateEffect(effectData);
    });
  }

  removeEffectsFromSD(socketData) {
    const mainKingdom = this.kingdomsController.getMainKingdom();

    socketData.forEach((effectData) => {
      mainKingdom.removeEffect(effectData.id);
    });
  }

  cancelCardPlay(data) {
    const { cardId, message } = data;

    const card = this.entitiesController.findById(cardId);
    const kingdom = this.kingdomsController.getMainKingdom();

    kingdom.moveCardBackToHand(card);
    this.interfaceController.infoUnderBar.showMessage(message);

    card.hideSpinner();
  }

  loadGameFromHost(data, delta) {
    const { map, kingdoms, entities, market, cycleTime, toCycleEnd, status, countdownTimer } = data;

    this.connectionController.clearPackets();
    this.kingdomsController.loadFromSD(kingdoms);
    this.entitiesController.resetToSocketData(entities, delta);
    this.mapController.loadFromSD(map);
    this.marketController.loadFromSD(market);

    this.setCycleTime(cycleTime);
    this.setToCycleEnd(toCycleEnd);

    this.interfaceController.createMainKingdomInterface();
    this.interfaceController.focusOnMainHeadquarter();

    this.setStatus(status);
    this.setCountdownTimer(countdownTimer);

    this.onPrepared();
  }

  updateGameFromHost = (data, delta) => {
    const {
      publicData: { changedFields, newEntities, destroyedEntities, kingdomUpdates, cycleEnd, status, countdownTimer, gameEnd, message },
      privateData: {
        drawnCards,
        discardedCards,
        returnedCards,
        trashedCards,
        updatedCards,
        triggerShuffiling,
        kingdomUpdate,
        addEffects,
        updateEffects,
        removeEffects,
      },
    } = data;

    if (newEntities) this.entitiesController.createFromSD(newEntities, delta);
    if (destroyedEntities) this.entitiesController.destroyFromSD(destroyedEntities);
    if (changedFields) this.mapController.updateFieldsFromSD(changedFields);

    if (kingdomUpdates) this.updateKingdomsFromSD(kingdomUpdates);
    if (cycleEnd) this.onCycleEnd(delta);
    if (status) this.setStatus(status);
    if (countdownTimer) this.setCountdownTimer(countdownTimer);

    if (drawnCards) this.drawCardsFromSD(drawnCards);
    if (discardedCards) this.discardCardsFromSD(discardedCards);
    if (returnedCards) this.returnCardsFromSD(returnedCards);
    if (trashedCards) this.trashCardsFromSD(trashedCards);
    if (updatedCards) this.updateCardsFromSD(updatedCards);
    if (triggerShuffiling) this.triggerShuffilingFromSD(triggerShuffiling);
    if (kingdomUpdate) this.updateMainKingdomFromSD(kingdomUpdate);

    if (addEffects) this.addEffectsFromSD(addEffects);
    if (updateEffects) this.updateEffectsFromSD(updateEffects);
    if (removeEffects) this.removeEffectsFromSD(removeEffects);

    if (gameEnd) this.onEndFromSD(message);
  };

  afterPlayCard = (cardId, kingdomId, response) => {
    const card = this.entitiesController.findById(cardId);
    const kingdom = this.kingdomsController.findKingdomById(kingdomId);

    if (response.can) {
      const cardData = card.getData();
      const { energyCost } = cardData;

      if (energyCost) {
        kingdom.changeEnergy(-energyCost);
      }
    } else {
      kingdom.moveCardBackToHand(card);
      this.interfaceController.infoUnderBar.showMessage(response.message);
    }

    card.hideSpinner();
  };

  calculateCycle(time) {
    if (this.toCycleEnd > 0) {
      this.toCycleEnd -= this.toCycleEnd > time ? time : this.toCycleEnd;
      return;
    }

    if (!this.advancedLogicPart) return;

    this.onCycleEnd();
  }

  onCycleEnd(delta = 0) {
    this.entitiesController.onCycleEnd();
    this.mapController.onCycleEnd();

    this.toCycleEnd = this.cycleTime - delta / FRAMES_DELAY;

    if (this.shouldSendEvent()) this.connectionController.onCycleEnd();
  }

  cycleProgress() {
    if (!this.toCycleEnd) return 0;
    return 1 - this.toCycleEnd / this.cycleTime;
  }

  getCycleTime() {
    return this.cycleTime;
  }

  resetCycleTimer() {
    this.toCycleEnd = this.cycleTime;
  }

  togglePaused() {
    this.setStatus(this.isPlaying() ? PAUSED : PLAYING);
  }

  pause = () => {
    this.setStatus(PAUSED);
  };

  resume = () => {
    this.setStatus(PLAYING);
  };

  setStatus(status) {
    this.status = status;
    if (this.shouldSendEvent()) this.connectionController.onStatusChange(status);
  }

  onPrepared() {
    this.onReadyCallback && this.onReadyCallback();
  }

  onStart() {
    this.setStatus(PLAYING);
    if (this.advancedLogicPart) this.kingdomsController.onStart();
    this.interfaceController.makeMaingKingdomInterfaceInteractive();
  }

  startCountdown() {
    this.setStatus(WAITING);
    this.setCountdownTimer(perFrameTimer(COUNTDOWN_TO_START));
  }

  setCountdownTimer(countdownTimer) {
    this.countdownTimer = countdownTimer;
  }

  getStatus() {
    return this.status;
  }

  getCountdown() {
    return this.countdownTimer;
  }

  getPlayerId() {
    return this.playerId;
  }

  getPlayerToken() {
    return this.playerToken;
  }

  getPlayerNick() {
    return this.playerNick;
  }

  getServerUrl() {
    return this.serverUrl;
  }

  isCountdown = () => {
    return this.status === WAITING;
  };

  setCycleTime(time) {
    this.cycleTime = time;
  }

  setToCycleEnd(timer) {
    this.toCycleEnd = timer;
  }

  onEndFromSD = (message) => {
    this.interfaceController.onEnd(message);
  };

  checkForEnd = async () => {
    if (this.isOver) return false;

    const mainKingdom = this.kingdomsController.getMainKingdom();

    if (!mainKingdom) {
      this.isOver = true;
      await sleep(2000);
      return this.interfaceController.onEliminate();
    }

    const winner = this.kingdomsController.getWinner();

    if (winner) {
      this.isOver = true;
      await sleep(2000);
      this.interfaceController.onWin();
    }
  };

  destroy = (params = {}) => {
    if (this.destroyed) return false;
    this.destroyed = true;
    this.isOver = true;

    clearTimeout(this.loopTimeout);

    this.pixiApp.stop();
    this.pixiApp.destroy(true);

    this.entitiesController.destroy();
    if (this.isMultiplayer) this.connectionController.destroy();
    this.eventsController.destroy();
    this.inputsController.destroy();

    this.onEndCallback && this.onEndCallback(params);
  };

  toggleDevTools() {
    this.devTools.onToggle();
  }

  generateId() {
    return generateId();
  }
}
