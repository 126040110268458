import * as PIXI from 'pixi.js';

import { withLeftClick } from 'client/utils/with-left-click.js';

import { IMAGES } from 'common/consts/types/images.js';

import { InterfaceBase } from '../interface-base.js';

export class HexButtonSprite extends InterfaceBase {
  constructor(params) {
    super(params);

    this.text = params.text;
    this.iconType = params.iconType;
    this.zIndex = params.zIndex;

    this.width = 60;
    this.height = 69;
  }

  disable() {
    this.sprites.container.alpha = 0.5;
    this.sprites.container.eventMode = 'none';
  }

  enable() {
    this.sprites.container.alpha = 1;
    this.sprites.container.eventMode = 'static';
  }

  setupSprites() {
    this.createContainer();

    this.createBackground();
    this.createIcon();
    this.tintIcon();

    this.updatePosition();
  }

  tintIcon() {
    const color = this.getTintColor();

    if (!color) return;

    this.sprites.icon.tint = color;
  }

  getTintColor() {}

  onClick = () => {};

  createContainer() {
    super.createContainer();

    this.sprites.container.eventMode = 'static';
    this.sprites.container.cursor = 'pointer';
    this.sprites.container.on('pointerdown', withLeftClick(this.onClick));

    this.registerSprite(this.sprites.container);
  }

  createIcon() {
    this.sprites.icon = this.game.texturesManager.createSprite(this.iconType);
    this.sprites.icon.anchor.set(0.5, 0.5);

    this.sprites.container.addChild(this.sprites.icon);
  }

  createBackground() {
    this.sprites.background = this.game.texturesManager.createSprite(IMAGES.INTERFACE.BUTTONS.HEX);

    this.sprites.background.anchor.set(0.5, 0.5);
    this.sprites.background.hitArea = new PIXI.Circle(0, 0, 28);

    this.sprites.container.addChild(this.sprites.background);
  }

  updatePosition() {}

  afterResize() {
    this.updatePosition();
  }
}
