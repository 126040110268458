import { uniqueString } from 'common/helpers/data.js';

import { CardsBoard } from './cards-board.js';

export class MarketBoard extends CardsBoard {
  constructor(params) {
    super(params);

    this.type = params.type;

    this.cardsTypes = [];
  }

  initialize() {
    this.setCardsTypes();
    this.calculateLayout();
    this.createSprites();
    this.updateInterface();

    this.createCards();
    this.setCardsPositions();

    this.registerEventListeners();
  }

  getNumberOfCards() {
    return this.cardsTypes.length;
  }

  recreateCards() {
    this.destroyAllCards();

    this.setCardsTypes();
    this.createCards();

    this.calculateLayout();
    this.updateInterface();
  }

  registerEventListeners = () => {
    this.game.eventsController.addListener('marketReload', this);
    this.game.eventsController.addListener('blockMarketInteractive', this);
    this.game.eventsController.addListener('unblockMarketInteractive', this);
  };

  unregisterEventListeners = () => {
    this.game.eventsController.removeListener('marketReload', this);
    this.game.eventsController.removeListener('blockMarketInteractive', this);
    this.game.eventsController.removeListener('unblockMarketInteractive', this);
  };

  destroyAllCards = () => {
    this.cards.forEach((card) => {
      card.destroy();
    });

    this.cards = [];
  };

  createCards = () => {
    this.cards = [];

    this.cardsTypes.forEach((cardType) => {
      const card = this.game.cardsCreator.createCard({
        type: cardType,
        owner: this,
        zIndex: 105,
        interfaceContainer: this.sprites.boxContainer,
        id: uniqueString('I'),
      });

      this.cards.push(card);
    });

    this.cards.forEach((card) => {
      card.showFront();

      card.setContainer(this);
      card.setInteractive(this.interactive);
    });
  };

  onMarketReload = () => {
    this.recreateCards();
  };

  onBlockMarketInteractive = () => {
    this.interactiveBlockades++;
    if (this.interactiveBlockades === 1) this.setInteractive(false);
  };

  onUnblockMarketInteractive = () => {
    this.interactiveBlockades--;
    if (this.interactiveBlockades === 0) this.setInteractive(true);
  };

  destroy() {
    super.destroy();
    this.unregisterEventListeners();
  }

  onCardClick(card) {
    this.game.eventsController.runEvent('marketCardClick', { card });
  }

  onCloseClick = () => {
    this.game.interfaceController.closeMarkets();
  };
}
