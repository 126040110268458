import { CARD_EFFECT_TYPES } from 'common/consts/types/card-effect.js';

import { BaseCardEffect } from './base.js';

export class IncreaseEnergyProductionCardEffect extends BaseCardEffect {
  constructor(params) {
    super(params);

    this.type = CARD_EFFECT_TYPES.INCREASE_ENERGY_PRODUCTION;
  }

  execute({ kingdom, card }) {
    const { energyProduction } = card.getStats();
    kingdom.changeEnergyProduction(energyProduction);
  }
}
