import { CARD_EFFECT_TYPES } from 'common/consts/types/card-effect.js';

import { BaseCardEffect } from '../base.js';

export class ReplaceHandCardEffect extends BaseCardEffect {
  constructor(params) {
    super(params);

    this.type = CARD_EFFECT_TYPES.REPLACE_HAND;
  }

  execute({ kingdom }) {
    const cardsCound = kingdom.getHandCount();
    kingdom.discardHand();

    kingdom.orderDrawingCards(cardsCound + 1);
  }
}
