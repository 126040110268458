import { TOOLTIP } from 'common/consts/tooltip.js';
import { CARD_EFFECT_TYPES } from 'common/consts/types/card-effect.js';

import { BaseCardEffect } from '../base.js';

export class EnergyToBulletsCardEffect extends BaseCardEffect {
  constructor(params) {
    super(params);

    this.type = CARD_EFFECT_TYPES.ENERGY_TO_BULLETS;
  }

  getTooltipTypes() {
    return [TOOLTIP.TYPES.SPREAD];
  }

  execute({ kingdom, card, targets }) {
    const { spread } = card.getStats();

    const energy = kingdom.getEnergy();
    kingdom.changeEnergy(-energy);

    const shots = energy;

    const field = this.game.entitiesController.findById(targets.fieldId);
    kingdom.getHeadquarter().fireBulletsTask({ shots, spread, targets: [field.getPosition()] });
  }

  pickTarget({ kingdom }) {
    return kingdom.orderPickingShotTargetField();
  }
}
